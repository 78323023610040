import { Col, Row, Card, Container, Button, Modal, Form, FormGroup, Spinner, Table } from "react-bootstrap";
import Layout from "../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, useRef } from "react";
import { fundDeposit, getBusiness, getWallets, withdrawalRequest } from "../redux/slices/users";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createAdmin, createVirtualAccount, getAccounts, getAnalytics, getDashboardListing } from "../redux/slices/account.service";
import Swal from "sweetalert2";
import formatCurrency from "../utils/currencyFormmter";
// import receipt_avatar from '../assets/images/receipt.svg';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
  } from 'chart.js';
  import { Bar } from "react-chartjs-2";
import DataCardSm from "../components/analytics/DataCardSm";
import SupportTicket from "../components/SupportTicket";
import { getAllTickets } from "../redux/slices/support.slice";
import BusinessRows from "../components/BusinessRows";
import SalesChart from "../components/SaleChart";

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );
  
  
  const Home = () => {


    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const week_labels = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const hourly_labels = ['00:00:00', '08:00:00', '10:00:00', '12:00:00', '14:00:00', '16:00:00', '18:00:00', '20:00:00', '22:00:00', '23:00:00'];    


    const initialState = {
        isWalletFunding: false, 
        isWithdrawal: false,
        payload: { amount: null },
        loading: false,
        showForm: false,
        pinValue: ['', '', '', ''],
        wallet: {},
        show_balance: false,
        fee: 0.00,
        errors: {},
        analytics: [],
        greeting: null,
        inflowData: [],
        outflowData: [],
        labels: labels,
    }

    const [payload, setPayload] = useState({amount: null})
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const [pinValue, setpinValues] = useState(['', '', '', ''])
    const [fee, setFee] = useState(0.00)
    const [analytics, setAnalytics] = useState({})
    const [tickets, setTickets] = useState([])
    const [pending_verification, setPendingVerification] = useState([])
    const [invite, setInvite] = useState(false)
    const [params, setParams] = useState({type: 'month'})

    const [state, setState] = useState(initialState)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const { user } = useSelector((state) => state.auth)


    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                display: true
            },
        },
        scales: {
            x: {
                grid: { display: false }
            },
            y: {
                grid: { display: true }
            },
        }
    };

    const data = {
        labels: state.labels,
        datasets: [
            {
                label: 'Inflow',
                data: state.inflowData,
                borderColor: 'rgb(68, 55, 144)',
                backgroundColor: 'rgb(68, 55, 144)',
                fill: true,
                tension: 0.4,
            },
            {
                label: 'Outlow',
                data: state.outflowData,
                borderColor: 'rgb(100, 97, 255)',
                backgroundColor: 'rgb(100, 97, 255)',
                fill: false,
                tension: 0.4,
            },
        ],
    }

    const inflow = {
        monthly: [12000, 15000, 18000, 20000, 25000, 27000, 30000, 32000, 34000, 36000, 38000, 40000],
        daily: [5000, 6000, 7000, 8000, 10000, 9000, 8500],
        hourly: Array.from({ length: 24 }, () => Math.floor(Math.random() * 1000) + 500),
    };
    
    const outflow = {
        monthly: [8000, 12000, 14000, 16000, 20000, 22000, 24000, 26000, 28000, 30000, 32000, 34000],
        daily: [4000, 5000, 6000, 7000, 8500, 7500, 7000],
        hourly: Array.from({ length: 24 }, () => Math.floor(Math.random() * 800) + 300),
    };
    
    

    useEffect(() => {
        greetingCustomer()
        loadDashData()
    }, [0])

    useEffect(() => {
        getAnalyticsData()
    }, [params])

    const handleInput = (event) => {
        const { name, value } = event.target
        if(name === 'amount' || name === 'fund_amount') {
            const newValue = value.replace(/[^\d.]/g, '')
            setPayload((prevState)=> ({...prevState,[name]:newValue}))
        }
        else {
            setPayload((prevState)=> ({...prevState,[name]:value}))
        }
    }

    const handleValidation = (event) => {

        const regex = /^\d*\.?\d*$/;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    
        const {name, value} = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if(name === 'email' && !pattern.test(value)) {
            setErrors((prevState) => ({ ...prevState, [name]: 'Please enter a valid email address'}))
        }
        else if ((name ==='amount' || name === 'fund_amount') && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if (name ==='amount' && parseFloat(value.replace(/[^\d.]/g, '')) > 200000) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: 'You can not withdraw above maximum limit' }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }

        if(name === 'amount' && parseFloat(value.replace(/[^\d.]/g, '')) > 0) {
            calculateFees()
        }

    }

    const handleOtpInputs = (index, event) => {
        
        const value = event.target.value

        if(isNaN(value)) return;

        const pinValues = [...pinValue]
        pinValues[index] = value
        setpinValues(pinValues)

        if (value !== '' && index < 3) {
            inputRefs[index + 1].current.focus()
        }        
    }

    const loadTickets = () => {
        dispatch(getAllTickets())
        .then((res) => {
            // console.log(res)
            if(res.payload?.results) {
                let  ticketList = res.payload.results
                if(ticketList.length > 0) {
                    ticketList = ticketList.slice(0, 5)
                }
                setTickets(ticketList)
            }
        })
    }

    const loadDashData = () => {
        dispatch(getDashboardListing())
        .then((res) => {
            // console.log(res)
            if(res.payload?.analytics) {
                setAnalytics(res.payload.analytics)
            }
            if(res.payload?.pending_tickets) {
                setTickets(res.payload.pending_tickets)
            }
            if(res.payload?.pending_verification) {
                setPendingVerification(res.payload.pending_verification)
            }
        })
    }

    const calculateFees = ()  => {
        let amount = parseFloat(payload.amount);
        const percentage = (amount / 100) * 0.5
        const fee = percentage < 2500 ? percentage : 2500 
        setFee(fee)
        return fee
    }

    const getAnalyticsData = () => {
        setLoading(true)
        dispatch(getAnalytics(params))
        .then((response) => {
            // console.log("RESPONSE", response.payload)
            if(response.payload?.inflow) {
                const jsonData = response.payload
                setState((prevState)=> ({...prevState, 'analytics': jsonData}))
                generateChart(jsonData)
            }
        })
        .finally(() => setLoading(false))
    }

    const generateChart = (jsonData) => {
        
        const inflowData = labels.map((item, index) => {
            let ft = jsonData.inflow.filter(i => i.month_literal === item)
            if(ft.length) {
                return ft[0].transaction_amount
            }
            return 0
        })
        
        const outflowData = labels.map((item, index) => {
            let ft = jsonData.outflow.filter(i => i.month_literal === item)
            if(ft.length) {
                return ft[0].transaction_amount
            }
            return 0
        })

       setState((prevState)=> ({...prevState, 'inflowData': inflowData}))
       setState((prevState)=> ({...prevState, 'outflowData': outflowData}))
        
    }

    const handleAdminInvite = () => {
        setLoading(true)
        dispatch(createAdmin(payload))
        .then((response) => {
            if(response.payload.success !== true) {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message || 'An error has accoured.'
                })
            }
            else {
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message || 'Admin added successfully. We have sent them an invitation.'
                })
                .then(() => setInvite(false))
            }
        })
        .finally(() => setLoading(false))
    }

    const greetingCustomer = () => {
        const currentHour = new Date().getHours();
        let greetingTxt
        if (currentHour >= 5 && currentHour < 12) {
            greetingTxt = "Good morning";
          } else if (currentHour >= 12 && currentHour < 17) {
            greetingTxt = "Good afternoon";
          } else {
            greetingTxt = "Good evening";
        }
        setState((prevState)=> ({...prevState, 'greeting': greetingTxt}))
    }

    const handleAnalyticsFilter = (e) => {
        const {name, value} = e.target;
        setParams({...params, [name]: value})
        
        if(value === 'week') {
            setState({...state, labels: week_labels})
        }
        else if(value === 'day') {
            setState({...state, labels: hourly_labels})
        }
        else {
            setState({...state, labels: labels})
        }

    }
    

    return (
        <Layout title="Dashboard">
            <Container className="mt-5 px-4">
                {
                    loading ? (
                        <div className="page-loader">
                            <Spinner size="lg" variant="primary"/>
                        </div>
                    ) : null
                }
                <Col className="d-flex justify-content-between flex-wrap">
                    <p className="text-primary">
                       <strong>{state.greeting} 🌄 {user?.first_name}</strong>  <br />
                        <small className="text-muted">Here are the updates since you last logged in.</small>
                    </p>
                    <p>
                        <Button 
                            type="button" 
                            variant="primary"
                            onClick={ () => setInvite(true) }
                        >
                            <span> <i className="lni lni-plus"></i> </span>
                            Invite Admin
                        </Button>                  
                    </p>
                </Col>
                <Row className="mb-3">
                    <DataCardSm
                        title='Circulating Fund'
                        text='Total circulating funds'
                        amount={formatCurrency(analytics?.total_circulating_funds).with_currency}
                        className='bg--primary rounded-12 border-0'
                    />
                    <DataCardSm
                        title='All Time Transactions'
                        text='Total transacting funds'
                        amount={formatCurrency(analytics.total_transacting_funds).with_currency}
                        className='bg--secondary rounded-12 border-0'
                    />
                    <DataCardSm
                        title='Business'
                        text='Total businesses'
                        amount={formatCurrency(analytics.total_businesses).formatted}
                        className='bg--danger rounded-12 border-0'
                    />
                    <DataCardSm
                        title='Transactions'
                        text='All Transactions'
                        amount={formatCurrency(analytics.total_transactions).formatted}
                        className='bg--dark rounded-12 border-0'
                    />
                    <DataCardSm
                        title='Tickets'
                        text='Total Tickets'
                        amount={formatCurrency(analytics.total_tickets).formatted}
                        className='bg--success rounded-12 border-0'
                    />
                    <DataCardSm
                        title='Total Earning'
                        text='Total Earning'
                        amount={formatCurrency(analytics?.total_earnings).with_currency}
                        className='bg--primary rounded-12 border-0'
                    />
                    
                </Row>                
                <Row>
                    <Col md={8} lg={8} sm={12} className="gap-3">
                        <Card className="border-0 shadow-sm">
                            <Card.Header className="bg-white chart border-0 d-flex justify-content-endd flex-wrap">
                                {/* <select className="form-control w-25" name="type" onChange={handleAnalyticsFilter}>
                                    <option value="day">Hourly</option>
                                    <option value="week">Weekly</option>
                                    <option value="month" selected>Monthly</option>
                                </select> */}
                                <div className="w-25 form-control form-control-sm d-flex align-items-center text-muted nowrap">
                                    <span>From:</span>
                                    <input 
                                        type="date" 
                                        name="from_date" 
                                        className="border-0 ms-2" 
                                        onChange={handleAnalyticsFilter}
                                    />
                                </div>
                                <div className="w-25 form-control form-control-sm ms-2 d-flex align-items-center text-muted nowrap">
                                    <span>To:</span>
                                    <input 
                                        type="date" 
                                        name="to_date" 
                                        className="border-0 ms-2" 
                                        onChange={handleAnalyticsFilter}
                                    />
                                </div>
                                {/* {
                                    params.type === 'day' ? (
                                        <input 
                                            type="date" 
                                            name="date" 
                                            className="form-control form-control-sm w-25 ms-2" 
                                            onChange={handleAnalyticsFilter}
                                        />
                                    ) :  null
                                } */}
                            </Card.Header>
                            <Card.Body className="h-100">
                                <Bar options={options} data={data} />
                                {/* <SalesChart inflowData={inflow} outflowData={outflow} /> */}
                            </Card.Body>
                        </Card>
                    </Col>
                    
                    <Col md={4} lg={4} sm={12}>
                        <Card className="border-0 shadow-sm mb-3">
                            <Card.Header className="border-0 bg-white">
                                <p className="m-0 titled">Pending Tickets</p>
                            </Card.Header>
                            <Card.Body className="table-responsive-smd ticket-box">
                                <Table className="table table-sm">
                                    <SupportTicket 
                                        data={tickets}
                                        show_date={false}
                                        show_status={false}
                                        show_handle={false}
                                    />
                                </Table>
                                <div className="text-center">
                                    <button type="button" className="text-primary btn-plain" onClick={() => navigate('/tickets')}>
                                        See all <FontAwesomeIcon icon={'arrow-right'} />
                                    </button>
                                </div>
                            </Card.Body>
                        </Card>

                        <Card className="border-0 shadow-sm mb-3">
                            <Card.Header className="border-0 bg-white">
                                <p className="m-0 titled">Pending Business Verification</p>
                            </Card.Header>
                            <Card.Body className="table-responsive-smd ticket-box">
                                <Table className="table table-sm">
                                    <BusinessRows 
                                        data={pending_verification}
                                        show_date={true}
                                        show_status={false}
                                        show_type={false}
                                        show_email={false}
                                    />
                                </Table>
                                <div className="text-center">
                                    <button type="button" className="text-primary btn-plain" onClick={() => navigate('/businesses')}>
                                        See all <FontAwesomeIcon icon={'arrow-right'} />
                                    </button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={invite}
                    onHide={() => setInvite(false)}
                    backdrop="static"
                >
                    {/* <Modal.Header className="text-center">Invite Admin</Modal.Header> */}
                    <Modal.Body className="py-4 px-5">
                        <h5 className="text-center">Invite Admin</h5>
                        <Row>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0 text-sm">First Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="First Name"
                                    name="first_name"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['first_name'] && 
                                    <Form.Text className="text-danger">{errors['first_name']}</Form.Text>
                                }
                            </Form.Group>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0 text-sm">Last Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Last Name"
                                    name="last_name"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['last_name'] && 
                                    <Form.Text className="text-danger">{errors['last_name']}</Form.Text>
                                }
                            </Form.Group>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0 text-sm">Email</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Email"
                                    name="email"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['email'] && 
                                    <Form.Text className="text-danger">{errors['email']}</Form.Text>
                                }
                            </Form.Group>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0 text-sm">Role</Form.Label>
                                <Form.Select
                                    required
                                    name="role"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                >
                                    <option value="">Please select a role...</option>
                                    <option value="support">Support</option>
                                    <option value="sales">Sales</option>
                                    <option value="admin">Admin</option>
                                </Form.Select>
                                {
                                    errors['role'] && 
                                    <Form.Text className="text-danger">{errors['role']}</Form.Text>
                                }
                            </Form.Group>
                            <Form.Group as={Col} md={12} className="text-center mb-3 mt-3">
                                <Button 
                                    variant="outline-primary" 
                                    type="button" 
                                    onClick={() => setInvite(false)}
                                    className="px-4"
                                > 
                                    Cancel
                                </Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                <Button 
                                    variant="primary" 
                                    className="px-4" 
                                    type="button"
                                    onClick={ handleAdminInvite }
                                >Submit</Button>
                            </Form.Group>
                        </Row>
                    </Modal.Body>
                </Modal>
            </Container>
        </Layout>
    );
}

export default Home;