import { Row, Col, Container, Card, Table, Badge, Dropdown } from "react-bootstrap";
import Layout from "../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAnnouncements } from "../redux/slices/account.service";
import formatCurrency from "../utils/currencyFormmter";
import dateFormatter from "../utils/dateFormatter";

const Announcement = () => {


    const dispatch = useDispatch()

    const [announcements, setAnnouncements] = useState([]);

    useEffect(() => {
        loadAnnouncements()
    }, [0])


    const loadAnnouncements = () =>  {
        dispatch(getAnnouncements())
        .then((response) => {
            if(response.payload.results) {
                setAnnouncements(response.payload.results)
            }
        })
    }

 
    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <Col md={12} className="d-flex align-items-center justify-content-between">
                    <h3>Announcements</h3>
                </Col>

                <div className="mt-4">
                    <Card className="border-0 shadow-sm">
                        <Card.Body className="table-responsive">
                            <Table size="sm" className="table-color table-stripedd">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Date</th>
                                        <th>Title</th>
                                        <th>Message</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        announcements?.length ? (
                                            announcements?.map((item, index) => (
                                                <tr key={index} >
                                                    <td>
                                                        {index+1} .
                                                    </td>
                                                    <td> {dateFormatter(item.date)}  </td>
                                                    <td>{item.title}</td>
                                                    <td>{item.message} </td>
                                                </tr>
                                            ))
                                        )
                                        : 
                                        (
                                            <tr>
                                                <td colSpan={5} className="text-center text-muted">No data found</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </div>
            </Container>
        </Layout>
    );
}

export default Announcement;