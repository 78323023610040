import { Row, Col, Container, Card, Button, Spinner, Dropdown, Badge, Table, Modal, ModalBody, Form } from "react-bootstrap";
import Layout from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import rectangle from '../../assets/images/rectangle.svg'
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RecordFilter from "../../components/RecordFilter";
import Paginator from "../../components/table/Paginator";
import { getPaycodes } from "../../redux/slices/account.service";
import formatCurrency from "../../utils/currencyFormmter";

const Subscriptions = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.auth)


    const [loading, setLoading] = useState(false)
    const [show_form, setShowForm] = useState(false)
    const [payload, setPayload] = useState({
        page: 1,
        from_date: '',
        to_date: '',
        status: '',
        download: '',
        filter_by: '',
        sort_by: '',
        filter: '',
        gateway: '',
    })
    const [tab, setTab] = useState('subscription')
    const [subscriptions, setSubscriptions] = useState([])
    const [analytics, setAnalytics] = useState({})
    const [paginationData, setpaginationData] = useState({next: '', prev: ''})

    const plans = [
        {
            id: 1,
            name: 'Classic Plan',
            amount: '₦0.00',
            benefits: [
                {
                    id: 1,
                    status: 'active',
                    name: '0.25% per transaction capped at N2,000.00',
                    terms: false,
                    paid:false,
                },
                {
                    id: 2,
                    status: 'active',
                    name: 'Single withdrawal limit- N3million',
                    terms: false,
                    paid:false,
                },
                {
                    id: 3,
                    status: 'active',
                    name: 'Daily withdrawal limit N20million',
                    terms: false,
                    paid:false,
                },
                {
                    id: 4,
                    status: 'active',
                    name: 'Accepts international payments',
                    terms: false,
                    paid:false,
                },
                {
                    id: 5,
                    status: 'active',
                    name: 'Generate Paycode gift voucher',
                    terms: false,
                    paid:false,
                },
                {
                    id: 6,
                    status: 'active',
                    name: 'P2P for Suppliers/Vendor',
                    terms: false,
                    paid:false,
                },
                {
                    id: 7,
                    status: 'active',
                    name: 'Add maximum of 4 managers for alerts',
                    terms: false,
                    paid:false,
                },
                {
                    id: 8,
                    status: 'active',
                    name: 'Create 1-business bank account',
                    terms: true,
                    paid: false
                },
                {
                    id: 9,
                    status: 'active',
                    name: 'Instant Withdrawal',
                    terms: false,
                    paid: false
                },
                {
                    id: 10,
                    status: 'active',
                    name: 'Accept Paycode from customer',
                    terms: false,
                    paid: false
                },
                {
                    id: 11,
                    status: 'active',
                    name: 'CAC business registration',
                    terms: false,
                    paid: true
                },
                {
                    id: 12,
                    status: 'active',
                    name: '12/5 Customer support',
                    terms: false,
                    paid: false
                },
            ],
        },
        {
            id: 2,
            name: 'Premium Plan',
            amount: '₦8,300',
            benefits: [
                {
                    id: 1,
                    status: 'active',
                    name: '0.25% per transaction capped at N2,000.00',
                    terms: false,
                    paid:false,
                },
                {
                    id: 2,
                    status: 'active',
                    name: 'Single withdrawal limit- N3million',
                    terms: false,
                    paid:false,
                },
                {
                    id: 3,
                    status: 'active',
                    name: 'Daily withdrawal limit N20million',
                    terms: false,
                    paid:false,
                },
                {
                    id: 4,
                    status: 'active',
                    name: 'Accepts international payments',
                    terms: false,
                    paid:false,
                },
                {
                    id: 5,
                    status: 'active',
                    name: 'Generate Paycode gift voucher',
                    terms: false,
                    paid:false,
                },
                {
                    id: 6,
                    status: 'active',
                    name: 'P2P for Suppliers/Vendor',
                    terms: false,
                    paid:false,
                },
                {
                    id: 7,
                    status: 'active',
                    name: 'Add maximum of 4 managers for alerts',
                    terms: false,
                    paid:false,
                },
                {
                    id: 8,
                    status: 'active',
                    name: 'Create 2-business bank account',
                    terms: true,
                    paid: false
                },
                {
                    id: 9,
                    status: 'active',
                    name: 'Next day withdrawal',
                    terms: false,
                    paid: false
                },
                {
                    id: 10,
                    status: 'active',
                    name: 'Accept Paycode from customer',
                    terms: false,
                    paid: false
                },
                {
                    id: 11,
                    status: 'active',
                    name: 'Generate unlimited sales report',
                    terms: false,
                    paid: false
                },
                {
                    id: 12,
                    status: 'active',
                    name: 'CRM/Marketing Tool',
                    terms: false,
                    paid: false
                },
                {
                    id: 13,
                    status: 'active',
                    name: 'Invoicing Tool',
                    terms: false,
                    paid: false
                },
                {
                    id: 14,
                    status: 'active',
                    name: 'Personalized customer support',
                    terms: false,
                    paid: false
                },
                {
                    id: 15,
                    status: 'active',
                    name: 'Loan (Optional)',
                    terms: false,
                    paid: false
                },
            ],
        },
    ];

    const handleInput = (event) => {
        const {name, value} = event.target
        if(name === 'dob') {
            const dateParts = value.split('-');
            const dob = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
            setPayload((prevState) => ({...prevState, [name]: dob}))
        }
        else if(name === 'amount') {
            const newValue = parseFloat(value.replace(/[^\d.]/g, ''))
            setPayload((prevState)=> ({...prevState,[name]:newValue}))
        }
        else {
            setPayload((prevState) => ({...prevState, [name]: value}))
        }
    }

    const subscribeNow = (plan) => {
        Swal.fire({
            title: `${plan.name} subscription`,
            text: `${plan.amount} will be charged from your main wallet. Do you wish to continue?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Proceed"
        })
        .then((result) => {
            if (result.isConfirmed) {
                // dispatch()
                Swal.fire({
                    icon: "success",
                    title: "Successfully Subscribed! This is test though",
                })
                .then(() => {
                    navigate('/subscriptions/plans')
                })
            }
        })
    }

    const loadSubscriptions = () => {
        setLoading(true)
        dispatch(getPaycodes(payload))
        .then((response) => {
            if(response.payload?.results) {
                setSubscriptions(response.payload.results)
            }
            if(response.payload?.analytics) {
                setAnalytics(response.payload.analytics)
            }
            if(response.payload?.next || response.payload?.previous) {
                const data = {
                    next: response.payload?.next,
                    prev: response.payload?.previous,
                }
                setpaginationData(data)
            }
        })
        .finally(() => setLoading(false))
    }

    const managePaycode = (item, option) => {
        Swal.fire({
            icon: 'warning',
            text: `This paycode will be ${option}ed. Do you wish to continue?`,
            showConfirmButton: true,
            confirmButtonText: 'Yes, Proceed',
            showCancelButton: true,
            cancelButtonText: 'No, Cancel'
        })
        .then((result) => {
            if(result.isConfirmed) {
                handleActions(item, option)
            }
        })
    }

    const handleSearch = () => {
        loadSubscriptions()
    }

    const handlePagination = (data) => {
        setPayload({...payload, page: data})
    }

    const handleActions = (item, option) => {
        setLoading(true)
    }

    const handleSubscription  = (item, option) => {
        setLoading(true)
    }


    return (
        <Layout title="Subscription Management">
            <Container className="mt-3 px-4 mb-4">
                <Col className="d-flex mb-3 mt-2 justify-content-between">
                    <div className="d-flex">
                        <button 
                            type="button" 
                            className={`btn-plain rounded p-2 me-2  ${tab === "subscription" ? "bg-white text-primary" : "text-muted"}`}
                            onClick={ () => setTab("subscription") }
                        >
                            Subscription
                        </button>                  
                        <button 
                            type="button" 
                            className={`btn-plain rounded p-2 ms-2  ${tab === "management" ? "bg-white text-primary" : "text-muted"}`}
                            onClick={ () => setTab("management") }
                        >
                            Subscription fees management
                        </button>                  
                    </div>
                    <Button type="button" onClick={() => setShowForm(true)}>
                        <FontAwesomeIcon icon={'plus'} /> Add new Plan
                    </Button>
                </Col>
                {
                    tab === "subscription" ? (
                        <div className="mt-4">
                            {
                                loading ? (
                                    <div className="page-loader">
                                        <Spinner size="lg" variant="primary"/>
                                    </div>
                                ) : null
                            }
                            <Card className="mb-3 border-0 shadow-sm">
                                <Card.Body>
                                    <div className="d-flex justify-content-between">
                                        <h5>Overview</h5>
                                        <div className="d-flex justify-content-end">
                                            {/* <p>
                                                <select className="form-control">
                                                    {
                                                        months.length ? (
                                                            months.map(month => (
                                                                <option value={month.toLowerCase()} key={month}>{month}</option>
                                                            ))
                                                        ) : null
                                                    }
                                                </select>
                                            </p> */}
                                            {/* <p>
                                                <Button variant="outline-primary" type="button" className="ms-3">
                                                    View chart
                                                </Button>
                                            </p> */}
                                        </div>
                                    </div>
                                    <div className="mb-3 mt-3 analytic-row">
                                        <div className="shadow-sm card">
                                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                                <div className="icon bg--primary">
                                                </div>
                                                <div>
                                                    <h3 className="m-0 text-primary font-bold">{analytics?.all_paycodes || '0'}</h3>
                                                    <p className="text-muted text-sm m-0">Total Subscribed Business</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="shadow-sm card">
                                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                                <div className="icon bg-light">
                                                </div>
                                                <div>
                                                    <h3 className="m-0 text-success font-bold">{analytics?.all_active_paycode || '0'}</h3>
                                                    <p className="text-muted text-sm m-0">Active Subscriptions</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="shadow-sm card">
                                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                                <div className="icon">
                                                </div>
                                                <div>
                                                    <h3 className="m-0 text-danger font-bold">{analytics?.all_used_paycode || '0'}</h3>
                                                    <p className="text-muted text-sm m-0">Expired Subscriptions</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="shadow-sm card">
                                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                                <div className="icon">
                                                </div>
                                                <div>
                                                    <h3 className="m-0 text-warning font-bold">{analytics?.expired_paycode || '0'}</h3>
                                                    <p className="text-muted text-sm m-0">Expiring Subscription (5days)</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
        
                            <RecordFilter
                                handleInput={handleInput}
                                handleSearchSubmit={handleSearch}
                            />
        
                            <Card className="border-0 shadow-sm rounded-6">
                                <Card.Body className="table-responsive-lg">
                                    <Table size="sm" className="table-color">
                                        <thead>
                                            <tr>
                                                <th>WallX ID</th>
                                                <th>Business Name</th>
                                                <th>Subscription Type</th>
                                                <th>Number of Periods(months)</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                subscriptions?.length ? (
                                                    subscriptions.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                {item.reference}
                                                            </td>
                                                            <td>
                                                                <p className="m-0 d-flex gap-2 align-items-center">
                                                                    <img src={item.avatar || 'https://via.placeholder.com/50'} alt="avatar" width="30" className="rounded-circle"/>
                                                                    <strong>
                                                                        {item.owner || `${user?.first_name+' '+user?.last_name}`}
                                                                    </strong>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                {formatCurrency(item.amount).with_currency}
                                                            </td>
                                                            <td>
                                                                {formatCurrency(item.used_amount).with_currency}
                                                            </td>
                                                            <td>
                                                                <p className="d-flex m-0 align-items-center justify-content-between">
                                                                    <Badge 
                                                                        className={item.active && !item.locked ? 'badge-active' : 
                                                                        (item.used === 'used' ? 'badge-used' : 
                                                                        (item.locked ? 'badge-failed' : 'badge-pending')
                                                                        )}
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                            <circle cx="5" cy="5" r="5" fill={
                                                                                item.active && !item.locked ? "#008000" : 
                                                                                (item.used ? "#828282" : (item.locked ? "#dd3300" : "#FAB941"))
                                                                            }
                                                                            />
                                                                        </svg>
                                                                        <span className="ps-1">
                                                                            {item.active && !item.locked ? 'Active' : (item.used ? 'Used' : (item.locked ? 'Locked' : 'Expired'))}
                                                                        </span>
                                                                    </Badge> 
                                                                    <Dropdown>
                                                                        <Dropdown.Toggle variant="light" className="border-0 bg-white" size="sm" id="dropdown-basic">
                                                                            <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                        </Dropdown.Toggle>
            
                                                                        <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                            <Dropdown.Item 
                                                                                eventKey={1} 
                                                                                className="text-primary d-flex align-items-center gap-1" 
                                                                                onClick={() => handleSubscription(item, 'view')}
                                                                            >
                                                                            <i className="lni lni-eye" style={{fontSize: "20px"}}></i>  View Details
                                                                            </Dropdown.Item>
                                                                            <Dropdown.Divider />
                                                                            <Dropdown.Item 
                                                                                eventKey={1} 
                                                                                className="text-primary d-flex align-items-center gap-1" 
                                                                                onClick={() => handleSubscription(item, 'audit')}
                                                                            >
                                                                            <i className="lni lni-list" style={{fontSize: "20px"}}></i> Audit Trail
                                                                            </Dropdown.Item>
                                                                            {
                                                                                item.locked ? (
                                                                                    <>
                                                                                        <Dropdown.Divider />
                                                                                        <Dropdown.Item 
                                                                                            eventKey={2} 
                                                                                            className="d-flex align-items-center gap-1 text-primary"
                                                                                            onClick={ () => managePaycode(item, 'unlock') }
                                                                                        >
                                                                                            <FontAwesomeIcon icon={"lock-open"} /> Unlock PayCode
                                                                                        </Dropdown.Item>
                                                                                    </>
                                                                                ) : 
                                                                                <>
                                                                                    <Dropdown.Divider />
                                                                                    <Dropdown.Item 
                                                                                        eventKey={2} 
                                                                                        className="d-flex align-items-center gap-1 text-danger"
                                                                                        onClick={ () => managePaycode(item, 'lock') }
                                                                                    >
                                                                                        <FontAwesomeIcon icon={"lock"} /> Lock PayCode
                                                                                    </Dropdown.Item>
                                                                                </>
                                                                            }
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    ))
                                                )
                                                :
                                                (
                                                    <tr>
                                                        <td colSpan={5} className="text-center text-muted">No data found</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                            {
                                subscriptions?.length ? (
                                    <div className="table-responsive mt-2">
                                        <Paginator
                                            next={paginationData.next}
                                            prev={paginationData.prev}
                                            setCurrentPage={ handlePagination }
                                        />
                                    </div>
                                ) : null
                            }
                        </div>
                    ) : tab === "management" ?
                    (

                        plans.length ? (
                            <Row>
                                {
                                    plans.map((plan, index) => ( 
                                            <Col md={5} key={index}>
                                                <Card className="border-0 shadow-sm rounded-16">
                                                    <Card.Body className="py-4 pe-3 ps-3 pricing">
                                                        <h4 className="text-secondary">{plan.name}</h4>
                                                        <div className="mb-3 mt-3">
                                                            <h1 className="text-bold font-bold">{plan.amount}/<small className="text-sm">month</small> </h1>
                                                        </div>
                
                                                        <div className="plan">
                                                            <div className="pricing mb-3">
                                                                <p className="m-0">Other Prices:</p>
                                                                <div className="price-tags">
                                                                    <h5>
                                                                        ₦40,000/<small>6months</small>, 
                                                                        ₦80,000/<small>12months</small> 
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <h6 className="text-primaryd">Benefits</h6>
                                                            {
                                                                plan.benefits.length ? (
                                                                    <ul>
                                                                        {
                                                                            plan.benefits.map((plan, index) => (
                                                                                <li key={index}>
                                                                                <span className="me-3">
                                                                                        <img src={rectangle} alt="icon" />
                                                                                    </span> 
                                                                                    {plan.name} 
                                                                                        { 
                                                                                            plan.terms ?  
                                                                                                <small className="text-danger font-bold">(Please read T&C)</small> : 
                                                                                            plan.paid ?  
                                                                                                <small className="text-danger font-bold">(Paid service)</small> : null
                                                                                        }
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                ) : null
                                                            }
                                                            <Button 
                                                                type="button" 
                                                                variant="primary" 
                                                                // variant={plan.name === "Premium Plan" ? "primary" : "light"} 
                                                                disabled={plan.name === "Classic Plan"}
                                                                className={`form-control mt-5 `}
                                                                onClick={() => subscribeNow(plan)}
                                                            >
                                                                {/* className={`form-control mt-5 ${plan.name === 'Premium Plan' ? null : 'bg---primary'} `}> */}
                                                                    Subscribe
                                                            </Button>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                    ))
                                }
                            </Row>
                        ) : (
                            <div className="text-center col-md-12">
                                <p>No plans available</p>
                            </div>
                        )
                    ) : null
                }
                <Modal 
                    show={show_form}
                    onHide={() => setShowForm(false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Classic Plan </Modal.Title>
                    </Modal.Header>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Form.Group as={Col} md={6}>
                                    <Form.Label>Month</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                    <Form.Label>Prices(NGN)</Form.Label>
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group className="mb-3" as={Col} md={6}>
                                    <Form.Control 
                                        type="number" 
                                        placeholder="Enter transaction amount" 
                                        min={0}
                                        name="mini"
                                        onChange={handleInput}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" as={Col} md={6}>
                                    <Form.Control 
                                        type="number" 
                                        placeholder="Enter transaction charges" 
                                        min={0}
                                        name="charge_mini"
                                        onChange={handleInput}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group className="mb-3" as={Col} md={6}>
                                    <Form.Control 
                                        type="number" 
                                        placeholder="Enter transaction amount" 
                                        min={0}
                                        name="midi"
                                        onChange={handleInput}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" as={Col} md={6}>
                                    <Form.Control 
                                        type="number" 
                                        placeholder="Enter transaction charges" 
                                        min={0}
                                        name="charge_midi"
                                        onChange={handleInput}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group className="mb-3" as={Col} md={6}>
                                    <Form.Control 
                                        type="number" 
                                        placeholder="Enter transaction amount" 
                                        min={0}
                                        name="max"
                                        onChange={handleInput}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" as={Col} md={6}>
                                    <Form.Control 
                                        type="number" 
                                        placeholder="Enter transaction charges" 
                                        min={0}
                                        name="charge_max"
                                        onChange={handleInput}
                                    />
                                </Form.Group>
                            </Row>
                            <div className="d-grid gap-2">
                                <Button 
                                    variant="primary" 
                                    type="button"
                                    className="d-block"
                                >Update Changes</Button>
                            </div>
                        </Form>
                    </ModalBody>
                </Modal>
            </Container>
        </Layout>
    );
}

export default Subscriptions;