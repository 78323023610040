import { Row, Col, Container, Card, Button, Form, InputGroup, Spinner } from "react-bootstrap";
import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { changeAccountPassword, getProfile } from "../../redux/slices/users";
import { useNavigate } from "react-router-dom";
import { changePinConfirmation, logout } from "../../redux/slices/auth";

const Profile = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    // const { message } = useSelector((state) => state.message)
    // const { accounts, business, user } = useSelector((state) => state.user)
    
    const [payload, setPayload] = useState({})
    const [active_tab, setActiveTab] = useState(null)
    const [show_password, setShowPassword] = useState(false)
    const [security_option, setSecOption] = useState('profile')
    const [loading, setLoading] = useState(false)
    const [profile, setProfile] = useState({})
    const [initials, setInitials] = useState('')

    useEffect(() => {
        loadProfile()
    }, [0])

    const loadProfile = () => {
        dispatch(getProfile())
        .then((r) => {
            if(typeof(r.payload) === 'object') {
                let user = r.payload
                setProfile(r.payload)
                if(user.first_name !== undefined && user.last_name !== undefined) {
                    const firstInitials = user?.first_name.charAt(0)
                    const lastInitials = user?.last_name.charAt(0)
                    setInitials(firstInitials+lastInitials)
                }
            }
        })
    }

    const handleFormInput = (event) => {
        const { name, value } = event.target
        setPayload({ ...payload, [name]:value })
    }

    const togglePassword = () => {
        setShowPassword(!show_password)
    }

    const changePin = () => {
        setLoading(true)
        dispatch(changePinConfirmation(payload))
        .then((res) => {
            if(res.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: res.payload?.message
                })
                .then(() => window.location.reload())
            }
            else if(res.payload?.success === 'False') {
                Swal.fire({
                    icon: 'error',
                    text: res.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const changePassword = () => {
        setLoading(true)
        dispatch(changeAccountPassword(payload))
        .then((res) => {
            if(res.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: 'Password changed successfully. Please login to continue.'
                })
                dispatch(logout())
                navigate("/")
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: res.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const handleProfileUpdate = () => {
        Swal.fire({
            icon: 'error',
            text: 'This function is not available at the moment.',
        })
    }

    const actionConfirmation = (method) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you wish to apply these changes!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#443792',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes! Proceed'
        })
        .then((result) => {
            if (result.isConfirmed) {
                if(method === 'profile') {
                    changePin()
                }
                else if(method === 'password') {
                    changePassword()
                }
                else if(method === 'profile_update') {
                    handleProfileUpdate()
                }
            }
        })
    }

    const generateInitials = () => {
        if(profile.first_name !== undefined && profile.last_name !== undefined) {
            const firstInitials = profile?.first_name.charAt(0)
            const lastInitials = profile?.last_name.charAt(0)
            setInitials(firstInitials+lastInitials)
        }
    }

    const setSecurityOption = (option) => {
        setSecOption(option)
    }

    return (
        <Layout title="Admin Profile">
            <Container className="mt-3 px-4 mb-4">
                <Row className="justify-content-center">
                    <Col md={9}>
                        <Container fluid className="mt-5 bg-transparent">
                            {
                                active_tab === null ? (
                                    <Card className="border-0 mt-4 bg-transparent">
                                        <Card.Body>
                                            <Col className="d-flex gap-2 mx-auto w-50 px-4 py-1 justify-content-between px-0">
                                                <button 
                                                    type="button" 
                                                    className={security_option === 'profile' ? "text-primary font-bold  form-control" : "form-control bg-white border border-1 "}
                                                    onClick={ () => setSecurityOption('profile') }
                                                >
                                                    <span className="px-2">Admin Profile</span>
                                                </button>
                                                <button 
                                                    type="button" 
                                                    className={security_option === 'password' ? "text-primary font-bold  form-control" : "form-control bg-white border border-1 "}
                                                    onClick={ () => setSecurityOption('password') }
                                                >
                                                    <span className="px-2">Password</span>
                                                </button>
                                            </Col>
                                            {
                                                security_option === 'password' ? (
                                                    <Row className="justify-content-center mt-5">
                                                        <Col md={7} lg={6} sm={12}>
                                                            <div className="initials bg-success text-white font-bold mx-auto mb-3">
                                                                {initials}
                                                            </div>
                                                            <Form>
                                                                <Row>
                                                                    <Form.Group as={Col} md={12} sm={12} lg={12} className="mb-2">
                                                                        <Form.Label>Old Password</Form.Label>
                                                                        <InputGroup>
                                                                            <Form.Control
                                                                                type={show_password ? "text" : "password"}
                                                                                placeholder="Password"
                                                                                aria-describedby="inputGroupPrepend"
                                                                                required
                                                                                name="current_password"
                                                                                onChange={handleFormInput}
                                                                                maxLength={12}
                                                                                minLength={12}
                                                                            />
                                                                            <InputGroup.Text id="inputGroupPrepend" onClick={togglePassword}>
                                                                                <Button type="button" variant="light" className="border-0 input-group-btn-bg">
                                                                                    <FontAwesomeIcon icon={!show_password ? "far fa-eye-slash" : "far fa-eye"} />
                                                                                </Button>
                                                                            </InputGroup.Text>
                                                                        </InputGroup>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md={12} sm={12} lg={12} className="mb-2">
                                                                        <Form.Label>New Password</Form.Label>
                                                                        <InputGroup>
                                                                            <Form.Control
                                                                                type={show_password ? "text" : "password"}
                                                                                placeholder="Password"
                                                                                required
                                                                                name="new_password"
                                                                                onChange={handleFormInput}
                                                                                maxLength={12}
                                                                                minLength={12}
                                                                            />
                                                                            <InputGroup.Text id="inputGroupPrepend" onClick={togglePassword}>
                                                                                <Button type="button" variant="light" className="border-0 input-group-btn-bg">
                                                                                    <FontAwesomeIcon icon={!show_password ? "far fa-eye-slash" : "far fa-eye"} />
                                                                                </Button>
                                                                            </InputGroup.Text>
                                                                        </InputGroup>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md={12} sm={12} lg={12} className="mb-2">
                                                                        <Form.Label>Confirm Password</Form.Label>
                                                                        <InputGroup>
                                                                            <Form.Control
                                                                                type={show_password ? "text" : "password"}
                                                                                placeholder="Password"
                                                                                required
                                                                                name="password_confirmation"
                                                                                onChange={handleFormInput}
                                                                                maxLength={12}
                                                                                minLength={12}
                                                                            />
                                                                            <InputGroup.Text id="inputGroupPrepend" onClick={togglePassword}>
                                                                                <Button type="button" variant="light" className="border-0 input-group-btn-bg">
                                                                                    <FontAwesomeIcon icon={!show_password ? "far fa-eye-slash" : "far fa-eye"} />
                                                                                </Button>
                                                                            </InputGroup.Text>
                                                                        </InputGroup>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md={12} ms={12} lg={12} className="mb-2 mt-2">
                                                                        <Button 
                                                                            type="button" 
                                                                            variant="primary" 
                                                                            className="form-control"
                                                                            onClick={() => actionConfirmation('password')}
                                                                        >
                                                                            Update Changes <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                                                        </Button>
                                                                    </Form.Group>
                                                                </Row>
                                                            </Form>
                                                        </Col>
                                                    </Row>
                                                ) : null
                                            }
                                            {
                                                security_option === 'profile' ? (
                                                    <Row className="justify-content-center mt-5">
                                                        <Col md={7} lg={6} sm={12}>
                                                            <div className="initials bg-primary font-bold text-white mx-auto mb-3">
                                                                {initials}
                                                            </div>
                                                            <Form>
                                                                <Row>
                                                                    <Form.Group as={Col} md={12} sm={12} lg={12} className="mb-2">
                                                                        <Form.Label>First Name</Form.Label>
                                                                        <InputGroup>
                                                                            <Form.Control
                                                                                type="text"
                                                                                placeholder="First Name"
                                                                                required
                                                                                name="first_name"
                                                                                onChange={handleFormInput}
                                                                                value={profile?.first_name}
                                                                            />
                                                                        </InputGroup>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md={12} sm={12} lg={12} className="mb-2">
                                                                        <Form.Label>Last Name</Form.Label>
                                                                        <InputGroup>
                                                                            <Form.Control
                                                                                type="text"
                                                                                placeholder="Last Name"
                                                                                required
                                                                                name="last_name"
                                                                                onChange={handleFormInput}
                                                                                value={profile?.last_name}
                                                                            />
                                                                        </InputGroup>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md={12} sm={12} lg={12} className="mb-2">
                                                                        <Form.Label>Role</Form.Label>
                                                                        <InputGroup>
                                                                            <Form.Control
                                                                                type="text"
                                                                                placeholder="Role"
                                                                                required
                                                                                name="role"
                                                                                onChange={handleFormInput}
                                                                            />
                                                                        </InputGroup>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md={12} sm={12} lg={12} className="mb-2">
                                                                        <Form.Label>Email Address</Form.Label>
                                                                        <InputGroup>
                                                                            <Form.Control
                                                                                type="email"
                                                                                placeholder="Email"
                                                                                required
                                                                                name="email"
                                                                                onChange={handleFormInput}
                                                                                value={profile?.email}
                                                                            />
                                                                        </InputGroup>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md={12} ms={12} lg={12} className="mt-2">
                                                                        <Button 
                                                                            type="button" 
                                                                            variant="primary" 
                                                                            className="form-control"
                                                                            onClick={() => actionConfirmation('profile_update')}
                                                                        >
                                                                            Update Changes <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                                                        </Button>
                                                                    </Form.Group>
                                                                </Row>
                                                            </Form>
                                                        </Col>
                                                    </Row>
                                                ) : null
                                            }
                                        </Card.Body>
                                    </Card>
                                ) : null
                            }
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
}

export default Profile;