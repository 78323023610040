import { Row, Col, Container, Card, Form, Button, Table, Badge, Spinner, Dropdown, Modal } from "react-bootstrap";
import Layout from "../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../components/table/Paginator";
import { useEffect, useState } from "react";
import DrewsModal from "../components/modal/DrewsModal";
import { useDispatch } from "react-redux";
import { getAudits, getBusinesses } from "../redux/slices/account.service";
import formatCurrency from "../utils/currencyFormmter";
import Logo from "../components/Logo";
import dateFormatter from "../utils/dateFormatter";
import RecordFilter from "../components/RecordFilter";

import {
    Chart as ChartJS,
    ArcElement,
    Title,
    Legend
  } from 'chart.js';
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Title, Legend);

const Audit = () => {


    const dispatch = useDispatch()

    const [modalShow, setModalShow] = useState(false)
    const [transaction, setTransaction] = useState({})
    const [transactions, setTransactions] = useState([])
    const [results, setResults] = useState([])
    const [analytics, setAnalytics] = useState({})
    const [receipt, setReceipt] = useState({})
    const [show_receipt, setShowReceipt] = useState(false)
    const [copiedText, setCopiedText] = useState('');
    const [tab, setTab] = useState('all')
    const [paginationData, setpaginationData] = useState({next: '', prev: ''})
    const [loading, setLoading] = useState(false)
    const [payload, setPayload] = useState({
        page: 1,
        from_date: '',
        to_date: '',
        status: '',
        download: '',
        filter_by: '',
        sort_by: '',
        filter: '',
        gateway: '',
    })

    useEffect(() => {
        loadAudits()
    }, [payload.page])

    // ------- Chart configuration ------------ //

    const DATA_COUNT = 5;
    // const NUMBER_CFG = {count: DATA_COUNT, min: 0, max: 100};

    const data = {
        labels: ['Lagos', 'Abuja', 'Akwa-Ibon', 'Jos', 'Benue'],
        datasets: [
            {
            label: 'Dataset 1',
            data: [10, 20, 30, 40, 50],
            backgroundColor: [
                'red',
                'blue',
                'orange',
                'lightgreen',
                'skyblue',
             ]
            }
        ]
    };

    const chartConfig = {
        type: 'pie',
        data: data,
        options: {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: true,
                    text: 'Audit Trail'
                }
            }
        },
    };

    // ------- Chart configuration ------------ //

    const selectTransaction = (id) => {
        const transaction = transactions.filter(item => item.reference === id)[0]
        if(transaction.note == 'Electricity') {
            setReceipt(transaction);
            setShowReceipt(true)
        }
        else {
            setTransaction(transaction)
            setModalShow(true)
        }
    }

    // const loadTransactions = () =>  {
    //     dispatch(getTransactionHistory())
    //     .then((response) => {
    //         if(response.payload.results) {
    //             setTransactions(response.payload.results)
    //             setTimeout(() => {
    //                 filterTransactions(response.payload.results, 'all')
    //             }, 1000);
    //         }
    //         if(response.payload?.analytics) {
    //             setAnalytics(response.payload.analytics)
    //         }
    //     })
    // }

    const loadBusinesses = () =>  {
        dispatch(getBusinesses())
        .then((response) => {
            if(response.payload.results) {
                setResults(response.payload.results)
            }
            if(response.payload?.analytics) {
                setAnalytics(response.payload.analytics)
            }
        })
    }

    const loadAudits = () =>  {
        setLoading(true)
        dispatch(getAudits({paycode: '609314', reference: 'ftXXSMH9kHTacT24'}))
        .then((response) => {
            console.log("AUDIT", response)
            // if(response.payload.results) {
            //     setResults(response.payload.results)
            // }
            // if(response.payload?.analytics) {
            //     setAnalytics(response.payload.analytics)
            // }

            if(response.payload?.next || response.payload?.previous) {
                const data = {
                    next: response.payload?.next,
                    prev: response.payload?.previous,
                }
                setpaginationData(data)
            }
        })
        .finally(() => setLoading(false))
    }

    const handleCopy = (copyText) => {
        navigator.clipboard.writeText(copyText)
          .then(() => {
            setCopiedText(copyText);
          })
          .catch(err => {
            console.error('Error copying text: ', err);
          });
    };

    const handleSearch = () => {
        loadAudits()
    }

    const handleInput = (event) => {
        const {name, value} = event.target
        setPayload((prevState)=> ({...prevState,[name]:value}))
    }

    const handlePagination = (data) => {
        setPayload({...payload, page: data})
    }
 
    return (
        <Layout title="Audit Trails">
            <Container className="mt-4 px-4 mb-4">
                {
                    loading ? (
                        <div className="page-loader">
                            <Spinner size="lg" variant="primary"/>
                        </div>
                    ) : null
                }
                <Row className="mb-3">
                    <Col md={12} className="mb-3 d-flex gap-2 wrap table-responsive">
                        <Button 
                            type="button" 
                            variant="light" 
                            className="tab-buttons"
                            active={tab === 'all' ? true : false}
                            onClick={ () => setTab('all')}
                        >
                            Business Activities
                        </Button>
                        <Button 
                            type="button" 
                            variant="light" 
                            className="tab-buttons"
                            active={tab === 'history_log' ? true : false}
                            onClick={ () => setTab('history_log')}
                        >
                            History Log
                        </Button>
                        <Button 
                            type="button" 
                            variant="light" 
                            className="tab-buttons"
                            active={tab === 'location_stats' ? true : false}
                            onClick={ () => setTab('location_stats')}
                        >
                            Location Statistics
                        </Button>
                    </Col>
                </Row>

                <div className="mt-4">
                    <div className="mb-3 mt-3 analytic-row">
                        <div className="card border-0 shadow-sm bg--primary">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon ">
                                </div>
                                <div>
                                    <h3 className="m-0 text-primary font-bold">{analytics?.total_transactions || '0'}</h3>
                                    <p className="text-muted text-sm m-0">All Businesses</p>
                                </div>
                            </div>
                        </div>
                        <div className="card border-0 shadow-sm">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon bg-light">
                                </div>
                                <div>
                                    <h3 className="m-0 text-success font-bold">{analytics?.total_successful_transactions || '0'}</h3>
                                    <p className="text-muted text-sm m-0">Total Businesses Online</p>
                                </div>
                            </div>
                        </div>
                        <div className="card border-0 shadow-sm">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon">
                                </div>
                                <div>
                                    <h3 className="m-0 text-warning font-bold">{analytics?.total_failed_transactions || '0'}</h3>
                                    <p className="text-muted text-sm m-0">Last Seen 24hours ago</p>
                                </div>
                            </div>
                        </div>
                        <div className="card border-0 shadow-sm">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon">
                                </div>
                                <div>
                                    <h3 className="m-0 text-secondary font-bold">{analytics?.total_pending_transactions || '0'}</h3>
                                    <p className="text-muted text-sm m-0">Last 1month +</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <RecordFilter
                        handleInput={handleInput}
                        handleSearchSubmit={handleSearch}
                    />

                    <Card className="border-0 shadow-sm">
                        <Card.Body className="table-responsive">
                            <Table size="sm" className="table-color table-stripedd">
                                {
                                    tab === 'location_stats' ? 
                                        <>
                                            <div className="row justify-content-center">
                                                <div className="col-md-6 col-sm-12">
                                                    <Pie options={chartConfig} data={data} />
                                                </div>
                                            </div>
                                        </>
                                    :
                                    tab === 'history_log' ? 
                                    <>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Location</th>
                                                <th>IP Address</th>
                                                <th>Browser</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                results?.length ? (
                                                    results?.map((item, index) => (
                                                        <tr key={index+tab}>
                                                            <td>
                                                                <div className="m-0 d-flex gap-2 align-items-center">
                                                                    <small className={item.status === 'success' ? 'drews-badge drews-badge-success-circle' : 'drews-badge drews-badge-error-circle'}>
                                                                        <i className="lni lni-arrow-top-right"></i>
                                                                    </small>
                                                                    <strong>
                                                                        <p className="m-0">{item.business_name} </p>  
                                                                    </strong>
                                                                </div>
                                                            </td>
                                                            <td> {item.location}  </td>
                                                            <td>{item.ip_address}</td>
                                                            <td>{item.browser}</td>
                                                            <td>{dateFormatter(item.date)}</td>
                                                        </tr>
                                                    ))
                                                )
                                                : 
                                                (
                                                    <tr>
                                                        <td colSpan={4} className="text-center text-muted">No data found</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>                                           
                                    </>
                                    :
                                    <>
                                        <thead>
                                            <tr>
                                                <th>Wallx ID</th>
                                                <th>Name</th>
                                                <th>Location</th>
                                                <th>Last Seen</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                results?.length ? (
                                                    results?.map((item, index) => (
                                                        <tr key={index+tab}>
                                                            <td>{item.id}</td>
                                                            <td>
                                                                <div className="m-0 d-flex gap-2 align-items-center">
                                                                    <small className={item.status === 'success' ? 'drews-badge drews-badge-success-circle' : 'drews-badge drews-badge-error-circle'}>
                                                                        <i className="lni lni-arrow-top-right"></i>
                                                                    </small>
                                                                    <strong>
                                                                        <p className="m-0">{item.business_name} </p>  
                                                                    </strong>
                                                                </div>
                                                            </td>
                                                            <td> {item.location}  </td>
                                                            <td>{item.last_seen}</td>
                                                            <td>
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="light" className="border-0 bg-transparent" size="sm" id="dropdown-basic">
                                                                        <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                    </Dropdown.Toggle>
        
                                                                    <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                        <Dropdown.Item 
                                                                            eventKey={1} 
                                                                            className="text-primary d-flex align-items-center gap-1" 
                                                                            // onClick={() => selectTransaction(item.reference)}
                                                                        >
                                                                        <i className="lni lni-eye" style={{fontSize: "20px"}}></i>  View Details
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Divider />
                                                                        <Dropdown.Item 
                                                                            eventKey={2} 
                                                                            className="text-success d-flex align-items-center gap-1"
                                                                            // onClick={ () => selectTransaction(item.reference) }
                                                                        >
                                                                            <i className="lni lni-question-circle"></i> Approve Business
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>
                                                    ))
                                                )
                                                : 
                                                (
                                                    <tr>
                                                        <td colSpan={5} className="text-center text-muted">No data found</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>                                           
                                    </>
                                }
                            </Table>
                        </Card.Body>
                    </Card>
                    {
                        results?.length ? (
                            <div className="table-responsive mt-2">
                                <Paginator
                                    next={paginationData.next} 
                                    prev={paginationData.prev}
                                    setCurrentPage={ handlePagination }
                                />
                            </div>
                        ) : null
                    }
                </div>
                <DrewsModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    dismissable={true}
                    title={transaction.reference}
                    badge_text={transaction.type}
                >
                    <Row>
                        <Col md={6}>
                            <div className="modal-profile">
                                <ul>
                                    <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="text-muted">Transaction ID</small>
                                                {transaction.reference} 
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="text-muted">Note</small>
                                                {transaction?.note || 'Nil'} 
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="text-muted">Beneficiary Account</small>
                                                {transaction?.balance || 'Nil'}
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="text-muted">Status</small>
                                                <span 
                                                className={transaction.status === 'success' ? 'text-success' : 
                                                (transaction.status === 'failed' ? 'text-danger' : 
                                                (transaction.status === 'used' ? 'text-dark' : 'text-warning'))}
                                                >
                                                    {transaction.status}
                                                </span> 
                                            </p>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </Col>
                        <Col md={6} className="modal-profile">
                            <ul>
                                <li>
                                    <div>
                                        <p className="pt-2">
                                            <small className="text-muted">Amount</small>
                                            {formatCurrency(transaction.amount).with_currency}
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <p className="pt-2">
                                            <small className="text-muted">Transaction Date</small>
                                            {transaction.date}
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </DrewsModal>

                <Modal 
                    show={show_receipt} 
                    fullscreen={true} 
                    onHide={() => setShowReceipt(false)}
                    dialogClassName="receipt-screen-modal"
                    backdrop="static"
                >
                    <Modal.Header>
                        <button className="btn-plain">
                            <FontAwesomeIcon icon="chevron-left" onClick={ () => setShowReceipt(false) }/>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="p-2">
                        <div className="invoice px-3 py-0 border border-0">
                            <div className="border-bottom border-bottom-1 d-flex align-items-center justify-content-between pb-2 py-2">
                                <Logo variant="dark"/>
                                <div>
                                    <h5 className="mb-0 text-right">Transaction Receipt</h5>
                                    <p className="text-muted text-sm m-0 text-right">Generated: {receipt.date != null ? dateFormatter(receipt.date) : ''}</p>
                                </div>
                            </div>
                            <div className="table-responsive mb-3">
                                <table className="table border-0">
                                    <tbody>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Transaction Amount
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {formatCurrency(receipt.amount).with_currency}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Transaction Type
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {receipt?.bill_type}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Service Provider
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {receipt?.provider || 'Nill'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Date
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {receipt.date != null ? dateFormatter(receipt.date) : ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Meter No:
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {receipt?.meterNo || 'Nill'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Token
                                            </td>
                                            <td className="text-primary border-0 text-right copy-text">
                                                {receipt?.tokenCode || 'Nill'} 
                                                <button className="btn-plain">
                                                    <FontAwesomeIcon icon={"copy"} className="text-success" onClick={ ()  => handleCopy(receipt?.tokenCode || 'Nill') }/>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Transaction ID
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {receipt?.order}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </Container>
        </Layout>
    );
}

export default Audit;