import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Form, Row, Col, Button} from "react-bootstrap";

const RecordFilter = (props) => {

    const handleInput = props.handleInput
    const handleSearchSubmit = props.handleSearchSubmit

    const  [searchTerm, setSearchTerm] = useState({type: '', filter_by: ''});

    const handleEntry = (e) => {
        handleInput(e)
        setSearchTerm({...searchTerm,  [e.target.name]: e.target.value})
        // console.log(searchTerm)
    }

    const handleSubmit = () => {
        handleSearchSubmit()
    }

    let searchParams = [{label: 'All', value: 'all'},{label: 'Date', value: 'date'}, {label: 'Amount', value: 'amount'}, {label: 'Type', value: 'type'}, {label: 'Currency', value: 'currency'}, {label: 'Status', value: 'status'}];

    return (
        <Row className="mb-3">
            <Form.Group as={Col} md={7} className="mb-2">
                {
                    searchTerm.filter_by === 'date' ? (
                        <div className="d-flex">
                            <div className="input-group me-2">
                                <span className="input-group-text">From</span>
                                <Form.Control
                                    type="date"
                                    placeholder="Search by  name, ID, amount, type, date ..."
                                    onChange={ handleEntry }
                                    name="from_date"
                                />
                            </div>
                            <div className="input-group">
                                <span className="input-group-text">To</span>
                                <Form.Control
                                    type="date"
                                    placeholder="Search by  name, ID, amount, type, date ..."
                                    onChange={ handleEntry }
                                    name="to_date"
                                />
                            </div>
                        </div>
                    )
                    :
                    searchTerm.filter_by === 'type' ? (
                        <Form.Select name="filter" onChange={ handleEntry }>
                            <option value="">Select Business Type</option>
                            <option value="Registered Business">Registered Business</option>
                            <option value="Starter Business">Starter Business</option>
                        </Form.Select>
                    )
                    :  
                    <Form.Control
                        type="search"
                        placeholder="Search ..."
                        onChange={ handleEntry }
                        name="filter"
                    />
                    
                }
            </Form.Group>
            <Form.Group as={Col} md={3} className="mb-2">
                <div className="input-group">
                    <span className="input-group-text">Filter By</span>
                    <select className="form-select" name="filter_by" onChange={ handleEntry }>
                        <option value="">--Select--</option>
                        {
                            props.params && props.params.length ? (
                                props.params.map((param, index) => (
                                    <option key={index} value={param.value}>{param.label}</option>
                                ))
                            ) :
                            searchParams.map((param, index) => (
                                <option key={index} value={param.value}>{param.label}</option>
                            ))
                        }
                    </select>
                </div>
            </Form.Group>
            {/* <Form.Group as={Col} md={3} className="mb-2">
                <div className="input-group">
                    <span className="input-group-text">Sort By</span>
                    <select className="form-select" name="sort_by" onChange={ handleEntry }>
                        <option value="">None</option>
                        <option value="DESC">Descending</option>
                        <option value="ASC">Ascending</option>
                    </select>
                </div>
            </Form.Group> */}
            <Form.Group as={Col} md={2} className="mb-2">
                <Button 
                    type="button" 
                    variant="primary" 
                    onClick={ handleSubmit }
                >
                    <FontAwesomeIcon icon="fa fa-search" style={{fontSize: '17px'}} />
                    <span className="px-2">Search</span>
                </Button>
            </Form.Group>
        </Row>
    );
}

export default RecordFilter;