import ServiceHelper from "./service.helper";

export default class SupportService {

    static createTicket(payload) {
        return ServiceHelper.handlePostRequest('/adminapi/support/', payload)
    }

    static replyTicket(payload) {
        return ServiceHelper.handlePostRequest('/adminapi/ticket_response/', payload)
    }

    static getTickets() {
        return ServiceHelper.handleGetRequest('/adminapi/support/')
    }

    static getTicketReplies(id) {
        return ServiceHelper.handleGetRequest(`/adminapi/support/?action=replies&ticket_id=${id}`)
    }

    static markResolved(id) {
        return ServiceHelper.handlePatchRequest(`/ticket_response/`, {ticket_id: id})
    }
}