import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from './message';
import UserService from '../../services/user.service';
import ManagerService from '../../services/manager.service';


// Get user profile details
export const getProfile = createAsyncThunk(
  "auth/user/",
  async(thunkAPI) => {
    try {
      const response = await UserService.getProfile();
      return response[0];
    } catch (error) {
      console.log(error)
      return error
    }
  }
)

// Get user business data
export const getBusiness = createAsyncThunk(
  "/merchants/",
  async(thunkAPI) => {
    try {
      const response = await UserService.getStore();
      return response;
    } catch (error) {
      console.log(error)
      return error
    }
  }
)

// Get user business data
export const getMemberBusiness = createAsyncThunk(
  "/members/merchants_details/",
  async(thunkAPI) => {
    try {
      const response = await ManagerService.getStore();
      return response;
    } catch (error) {
      console.log(error)
      return error
    }
  }
)

// Get user wallets data
export const getWallets = createAsyncThunk(
  "/wallet_details/",
  async(thunkAPI) => {
    try {
      const response = await UserService.walletDetails();
      // console.log('wallet details', response)
      return response.data;
    } catch (error) {
      // console.log(error)
      return error
    }
  }
)


// Create business info
export const createBusiness = createAsyncThunk(
  '/create_business/',
  async(payload, thunkAPI) => {
    try {
      const data = payload
        const response = await UserService.createBusiness(data)
        console.log(response)
        thunkAPI.dispatch(userSlice.actions.setBusiness(data))
        thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
        return response
      } catch (error) {
        console.log(error)
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return thunkAPI.rejectWithValue()
    }
  }
)

// Upgrade business account
export const upgradeAccount = createAsyncThunk(
  '/account_upgrade/',
  async(payload, thunkAPI) => {
    try {
      const data = payload
        const response = await UserService.accountUpgrade(data)
        console.log(response)
        return response
      } catch (error) {
        return error
        return thunkAPI.rejectWithValue()
    }
  }
)

// Customer ratings
export const rateCustomer = createAsyncThunk(
  '/customer_rating/',
  async(payload, thunkAPI) => {
    try {
        const response = await UserService.customerRating(payload)
        // console.log(response)
        return response 
      } catch (error) {
        return error
    }
  }
)

// Get customer ratings
export const getRatings = createAsyncThunk(
  '/customer_rating/',
  async(payload, thunkAPI) => {
    try {
        const response = await UserService.rating(payload)
        return response 
      } catch (error) {
        return error
    }
  }
)

/**  HMO  */

// check HMO eligibility
export const checkEligibility = createAsyncThunk(
  '/check_hmo_eligibility/',
  async(thunkAPI) => {
    try {
        const response = await UserService.hmoEligibility()
        return response 
      } catch (error) {
        return error
    }
  }
)

// Enroll Customer for HMO
export const hmoEnrollment = createAsyncThunk(
  '/accept_hmo_perk/',
  async(payload, thunkAPI) => {
    try {
        const response = await UserService.hmoPeck(payload)
        return response 
      } catch (error) {
        return error
    }
  }
)

// submit withdrawal limit request
export const withdrawalLimitRequest = createAsyncThunk(
  '/withdrawal_limit/',
  async(payload, thunkAPI) => {
    try {
        const response = await UserService.limitRequest(payload)
        console.log(response)
        return response 
      } catch (error) {
        return error
    }
  }
)

export const addCustomer = createAsyncThunk(
  '/customers_list/',
  async(payload, thunkAPI) => {
    try {
        const response = await UserService.createCustomer(payload)
        thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
        return response
      } catch (error) {
        console.log(error)
        return error
        return thunkAPI.rejectWithValue()
    }
  }
)

/**  MANAGERS  */

export const addUser = createAsyncThunk(
  '/managers/',
  async(payload, thunkAPI) => {
    try {
        const response = await UserService.createUser(payload)
        thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
        return response
      } catch (error) {
        console.log(error)
        return error
        return thunkAPI.rejectWithValue()
    }
  }
)

export const getMembers = createAsyncThunk(
  '/managers/',
  async(thunkAPI) => {
    try {
        const response = await UserService.getUsers()
        return response
      } catch (error) {
        return thunkAPI.rejectWithValue()
    }
  }
)

export const deleteMember = createAsyncThunk(
  '/manage_team/',
  async(member_id, thunkAPI) => {
    try {
        const response = await UserService.deleteUser(member_id)
        thunkAPI.dispatch(setMessage({message: response?.message, status: 'True' }))
        return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error?.message, status: 'False' }))
        return thunkAPI.rejectWithValue()
    }
  }
)

export const manageMember = createAsyncThunk(
  '/manage_team/',
  async(payload, thunkAPI) => {
    try {
        const response = await UserService.manageUser(payload)
        thunkAPI.dispatch(setMessage({message: response?.message, status: 'True' }))
        return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error?.message, status: 'False' }))
        return thunkAPI.rejectWithValue()
    }
  }
)

/*** Marketing */

export const getCustomersList = createAsyncThunk(
  '/customers_list/',
  async(thunkAPI) => {
    try {
        const response = await UserService.getCustomers()
        return response
      } catch (error) {
        return thunkAPI.rejectWithValue()
    }
  }
)

export const getSmsList = createAsyncThunk(
  '/customer_sms/',
  async(thunkAPI) => {
    try {
        const response = await UserService.getSms()
        return response
      } catch (error) {
        return thunkAPI.rejectWithValue()
    }
  }
)

export const getEmailList = createAsyncThunk(
  '/customer_email/',
  async(thunkAPI) => {
    try {
        const response = await UserService.getEmails()
        return response
      } catch (error) {
        return thunkAPI.rejectWithValue()
    }
  }
)

export const deleteSmsById = createAsyncThunk(
  '/custmer_sms/',
  async(id, thunkAPI) => {
    try {
        const response = await UserService.deleteSms(id)
        thunkAPI.dispatch(setMessage({message: response?.message, status: 'True' }))
        return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error?.message, status: 'False' }))
        return thunkAPI.rejectWithValue()
    }
  }
)

export const deleteCustomerById = createAsyncThunk(
  '/custmer_sms/',
  async(id, thunkAPI) => {
    try {
        const response = await UserService.deleteCustomer(id)
        thunkAPI.dispatch(setMessage({message: response?.message, status: 'True' }))
        return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error?.message, status: 'False' }))
        return thunkAPI.rejectWithValue()
    }
  }
)

export const deleteEmailById = createAsyncThunk(
  '/custmer_sms/',
  async(id, thunkAPI) => {
    try {
        const response = await UserService.deleteEmail(id)
        thunkAPI.dispatch(setMessage({message: response?.message, status: 'True' }))
        return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error?.message, status: 'False' }))
        return thunkAPI.rejectWithValue()
    }
  }
)

export const sendSms = createAsyncThunk(
  '/customer_sms/',
  async(payload, thunkAPI) => {
    try {
        const response = await UserService.createSms(payload)
        thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
        return response
      } catch (error) {
        console.log(error)
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return thunkAPI.rejectWithValue()
    }
  }
)

export const sendEmail = createAsyncThunk(
  '/customer_email/',
  async(payload, thunkAPI) => {
    try {
        const response = await UserService.createEmail(payload)
        thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
        return response
      } catch (error) {
        console.log(error)
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return thunkAPI.rejectWithValue()
    }
  }
)

export const withdrawalRequest = createAsyncThunk(
  '/withdrawal/',
  async(payload, thunkAPI) => {
    try { 
        const response = await UserService.withdrawal(payload)
        // thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
        return response
      } catch (error) {
        return error
        return thunkAPI.rejectWithValue()
    }
  }
)

export const fundDeposit = createAsyncThunk(
  '/funds_deposit/',
  async(payload, thunkAPI) => {
    try { 
        const response = await UserService.depositFund(payload)
        return response
      } catch (error) {
        return error
    }
  }
)

export const uploadProfilePhoto = createAsyncThunk(
  '/profile_photo/',
  async(payload, thunkAPI) => {
    try {
        const response = await UserService.updateProfilePhoto(payload)
        thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
        return response
      } catch (error) {
        console.log(error)
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return thunkAPI.rejectWithValue()
    }
  }
)

export const getSocialHandles = createAsyncThunk(
  '/social_media/',
  async(thunkAPI) => {
    try {
        const response = await UserService.getSocialMedia()
        return response
      } catch (error) {
        console.log(error)
        return error
    }
  }
)

export const socialMediaUpdate = createAsyncThunk(
  '/social_media/',
  async(payload, thunkAPI) => {
    try {
        const response = await UserService.saveSocialMedia(payload)
        thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
        return response
      } catch (error) {
        console.log(error)
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return thunkAPI.rejectWithValue()
    }
  }
)

export const verifyWalletPin = createAsyncThunk(
  '/social_media/',
  async(payload, thunkAPI) => {
    try {
        const response = await UserService.pinVerification(payload)
        return response
      } catch (error) {
        console.log(error)
        return error
    }
  }
)

export const changeAccountPassword = createAsyncThunk(
  '/change_password/',
  async(payload, thunkAPI) => {
    try {
        const response = await UserService.changePassword(payload)
        return response
      } catch (error) {
        console.log(error)
        return error
    }
  }
)

export const getRewards = createAsyncThunk(
  '/rewards/',
  async(thunkAPI) => {
    try {
        const response = await UserService.rewards()
        return response
      } catch (error) {
        console.log(error)
        return error
    }
  }
)


/** Notifications */
export const getUserNotifications = createAsyncThunk(
  '/notification/',
  async(thunkAPI) => {
    try {
        const response = await UserService.getNotifications()
        return response
      } catch (error) {
        console.log(error)
        return error
    }
  }
)

export const markAsRead = createAsyncThunk(
  '/notification/',
  async(id, thunkAPI) => {
    try {
        const response = await UserService.readNotifications(id)
        return response
      } catch (error) {
        console.log(error)
        return error
    }
  }
)

const userSlice = createSlice({
  name: "user",
  initialState: {
    is_store_created: false,
    business: null,
    // user: user
  },
  reducers: {
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    startLoading(state) {
      state.isLoading = true
    },
    setUser(state, action) {
      state.isLoading = false
      state.user = action.payload
    },
    setToken(state, action) {
      localStorage.setItem("token", action.payload); 
    },
    signOut(state) {
      state.isAuthenticated = false
      state.user = null
    },
    setBusiness(state, data) {
      state.business = data
    }
  },
  extraReducers: {
    [createBusiness.fulfilled]: (state, action) => {
      state.is_business_submitted = true
      state.is_store_created = true
      // state.business = action.payload
    },
    [createBusiness.rejected]: (state, action) => {
      state.is_store_created = false
    },
    [getProfile.fulfilled]: (state, action) => {
      state.is_store_created = action.is_store_created
      if(action.payload.id) {
        state.user = action.payload
      }
    },
    [getProfile.rejected]: (state, action) => {
      state.is_store_created = false
    },
    [getBusiness.fulfilled]: (state, action) => {
      state.business = action.payload?.length === 1 ? action.payload[0] : null
    },
    [getMemberBusiness.fulfilled]: (state, action) => {
      state.business = action.payload?.length === 1 ? action.payload[0] : null
    },
  }
})

const { reducer } = userSlice
export default reducer;