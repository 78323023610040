import { Row, Button, Col } from 'react-bootstrap';
import DrewsModal from './DrewsModal';


const AlertModal = (props) => {
    
    const { children } = props;

    return (
        <DrewsModal
            show={props.show}
            onHide={props.onHide}
            size="md"
            dismissable={true}
        >
            <Row className="justify-content-center">
                <Col md={12}>
                    <div className="text-center py-3">
                        {children}
                    </div>
                    <div className="text-center mt-4">
                        {
                            props.title ? (
                                <h5>{props.title}</h5>
                            ) : null
                        }
                        {
                            props.text ? (
                                <p className="pe-5 ps-5">{props.text}</p>
                            ) : null
                        }
                        {
                            props.buttonText ? (
                                <div className="mb-4 mt-4">
                                    <Button 
                                        type="button" 
                                        variant="primary"
                                        className="border border-1 text-white ps-5 pe-5"
                                        onClick={props.onClick || null}
                                        size="lg"
                                    >
                                        {props.buttonText}
                                    </Button>
                                </div>
                            ) : null
                        }
                    </div>
                </Col>
            </Row>
        </DrewsModal>
    );
}


export default AlertModal;