import { Row, Col, Container, Card, Button, Table, Badge, Dropdown, Form, Spinner, Modal } from "react-bootstrap";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../../components/table/Paginator";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPaycodes, lockUnlockPaycode, getAudits } from "../../redux/slices/account.service";
import Swal from "sweetalert2";
import formatCurrency from "../../utils/currencyFormmter";
import dateFormatter from "../../utils/dateFormatter";
import RecordFilter from "../../components/RecordFilter";

const Paycode = () => {

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth)

    const [paycode_modal, setPaycodeModal] = useState(false)
    const [audit_modal, setAuditModal] = useState(false)
    const [payload, setPayload] = useState({
        page: 1,
        from_date: '',
        to_date: '',
        status: '',
        download: '',
        filter_by: '',
        sort_by: '',
        filter: '',
        gateway: '',
    })
    const [loading, setLoading] = useState(false)
    const [paycodes, setPaycodes] = useState([])
    const [errors, setErrors] = useState({})
    const [analytics, setAnalytics] = useState({})
    const [audits, setAudits] = useState([])
    const [paycode, setPaycode] = useState({})
    const [paginationData, setpaginationData] = useState({next: '', prev: ''})

    useEffect(() => {
        loadPaycodes()
    }, [payload.page])

    const handleInput = (event) => {
        const {name, value} = event.target
        if(name === 'dob') {
            const dateParts = value.split('-');
            const dob = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
            setPayload((prevState) => ({...prevState, [name]: dob}))
        }
        else if(name === 'amount') {
            const newValue = parseFloat(value.replace(/[^\d.]/g, ''))
            setPayload((prevState)=> ({...prevState,[name]:newValue}))
        }
        else {
            setPayload((prevState) => ({...prevState, [name]: value}))
        }
    }

    const handleValidation = (event) => {

        const regex = /^\d*\.?\d*$/;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    
        const {name, value} = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if(name === 'email' && !pattern.test(value)) {
            setErrors((prevState) => ({ ...prevState, [name]: 'Please enter a valid email address'}))
        }
        else if (name ==='amount' && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }
    }

    const loadPaycodes = () => {
        setLoading(true)
        dispatch(getPaycodes(payload))
        .then((response) => {
            if(response.payload?.results) {
                setPaycodes(response.payload.results)
            }
            if(response.payload?.analytics) {
                setAnalytics(response.payload.analytics)
            }
            if(response.payload?.next || response.payload?.previous) {
                const data = {
                    next: response.payload?.next,
                    prev: response.payload?.previous,
                }
                setpaginationData(data)
            }
        })
        .finally(() => setLoading(false))
    }

    const paycodeSelection = (item, option) => {
        setPaycode(item)
        if(option === 'view') {
            setPaycodeModal(true)
            return;
        }
        if(option === 'audit') {
            setAuditModal(true)
            loadAudits(item)
        }
        else {
            handleActions(item, option)
        }
    }

    const handleActions = (item, option) => {
        setLoading(true)
        let request_payload = {
            action: option,
            reference: item.reference,
            paycode: item.token
        }
        dispatch(lockUnlockPaycode(request_payload)) 
        .then((response) => {
            if(response.payload?.success === true) {
                loadPaycodes()
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message || 'Successful'
                })
                .then(() => setPaycodeModal(false))
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message || 'Something went wrong'
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const loadAudits = (item) => {
        setLoading(true)
        let request_payload = {
            reference: item.reference,
            paycode: item.token
        }
        dispatch(getAudits(request_payload)) 
        .then((response) => {
            if(response.payload?.data) {
                setAudits(response.payload?.data)
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message || 'Something went wrong'
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const managePaycode = (item, option) => {
        Swal.fire({
            icon: 'warning',
            text: `This paycode will be ${option}ed. Do you wish to continue?`,
            showConfirmButton: true,
            confirmButtonText: 'Yes, Proceed',
            showCancelButton: true,
            cancelButtonText: 'No, Cancel'
        })
        .then((result) => {
            if(result.isConfirmed) {
                handleActions(item, option)
            }
        })
    }

    const handleSearch = () => {
        loadPaycodes()
    }

    const handlePagination = (data) => {
        setPayload({...payload, page: data})
    }
    

    return (
        <Layout title="PayCode Transaction History">
            <Container className="mt-4 px-4 mb-4">
                <div className="mt-4">
                    {
                        loading ? (
                            <div className="page-loader">
                                <Spinner size="lg" variant="primary"/>
                            </div>
                        ) : null
                    }
                    <Card className="mb-3 border-0 shadow-sm">
                        <Card.Body>
                            <div className="d-flex justify-content-between">
                                <h5>Overview</h5>
                                <div className="d-flex justify-content-end">
                                    <p>
                                        <select className="form-control">
                                            {
                                                months.length ? (
                                                    months.map(month => (
                                                        <option value={month.toLowerCase()} key={month}>{month}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                    </p>
                                    {/* <p>
                                        <Button variant="outline-primary" type="button" className="ms-3">
                                            View chart
                                        </Button>
                                    </p> */}
                                </div>
                            </div>
                            <div className="mb-3 mt-3 analytic-row">
                                <div className="shadow-sm card bg--primary">
                                    <div className="d-flex align-items-center gap-2 px-2 py-4">
                                        <div className="icon ">
                                        </div>
                                        <div>
                                            <h3 className="m-0 text-primary font-bold">{analytics?.all_paycodes || '0'}</h3>
                                            <p className="text-muted text-sm m-0">Total Paycodes</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="shadow-sm card">
                                    <div className="d-flex align-items-center gap-2 px-2 py-4">
                                        <div className="icon bg-light">
                                        </div>
                                        <div>
                                            <h3 className="m-0 text-success font-bold">{analytics?.all_active_paycode || '0'}</h3>
                                            <p className="text-muted text-sm m-0">Active Paycodes</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="shadow-sm card">
                                    <div className="d-flex align-items-center gap-2 px-2 py-4">
                                        <div className="icon">
                                        </div>
                                        <div>
                                            <h3 className="m-0 text-danger font-bold">{analytics?.all_used_paycode || '0'}</h3>
                                            <p className="text-muted text-sm m-0">Used Paycodes</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="shadow-sm card">
                                    <div className="d-flex align-items-center gap-2 px-2 py-4">
                                        <div className="icon">
                                        </div>
                                        <div>
                                            <h3 className="m-0 text-warning font-bold">{analytics?.expired_paycode || '0'}</h3>
                                            <p className="text-muted text-sm m-0">Expired Paycodes</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="shadow-sm card">
                                    <div className="d-flex align-items-center gap-2 px-2 py-4">
                                        <div className="icon">
                                        </div>
                                        <div>
                                            <h3 className="m-0 text-secondary font-bold">{formatCurrency(analytics?.total_paycode_value).with_currency || '0'}</h3>
                                            <p className="text-muted text-sm m-0">Total Paycode Value</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>

                    <RecordFilter
                        handleInput={handleInput}
                        handleSearchSubmit={handleSearch}
                    />

                    <Card className="border-0 shadow-sm rounded-6">
                        <Card.Body className="table-responsive-lg">
                            <Table size="sm" className="table-color">
                                <thead>
                                    <tr>
                                        <th>PayCode ID</th>
                                        <th>Beneficiary</th>
                                        <th>Amt Remaining</th>
                                        <th>Amt Used</th>
                                        <th>Date Created</th>
                                        <th>Token</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        paycodes?.length ? (
                                            paycodes.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {item.reference}
                                                    </td>
                                                    <td>
                                                        <p className="m-0 d-flex gap-2 align-items-center">
                                                            <img src={item.avatar || 'https://via.placeholder.com/50'} alt="avatar" width="30" className="rounded-circle"/>
                                                            <strong>
                                                                {item.owner || `${user?.first_name+' '+user?.last_name}`}
                                                            </strong>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        {formatCurrency(item.amount).with_currency}
                                                    </td>
                                                    <td>
                                                        {formatCurrency(item.used_amount).with_currency}
                                                    </td>
                                                    <td>
                                                        {dateFormatter(item.date)}
                                                    </td>
                                                    <td className="font-bold">
                                                      {item.token} 
                                                    </td>
                                                    <td>
                                                        <p className="d-flex m-0 align-items-center justify-content-between">
                                                            <Badge 
                                                                className={item.active && !item.locked ? 'badge-active' : 
                                                                (item.used === 'used' ? 'badge-used' : 
                                                                (item.locked ? 'badge-failed' : 'badge-pending')
                                                                )}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                    <circle cx="5" cy="5" r="5" fill={
                                                                        item.active && !item.locked ? "#008000" : 
                                                                        (item.used ? "#828282" : (item.locked ? "#dd3300" : "#FAB941"))
                                                                    }
                                                                    />
                                                                </svg>
                                                                <span className="ps-1">
                                                                    {item.active && !item.locked ? 'Active' : (item.used ? 'Used' : (item.locked ? 'Locked' : 'Expired'))}
                                                                </span>
                                                            </Badge> 
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="light" className="border-0 bg-white" size="sm" id="dropdown-basic">
                                                                    <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                </Dropdown.Toggle>
    
                                                                <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                    <Dropdown.Item 
                                                                        eventKey={1} 
                                                                        className="text-primary d-flex align-items-center gap-1" 
                                                                        onClick={() => paycodeSelection(item, 'view')}
                                                                    >
                                                                    <i className="lni lni-eye" style={{fontSize: "20px"}}></i>  View Details
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item 
                                                                        eventKey={1} 
                                                                        className="text-primary d-flex align-items-center gap-1" 
                                                                        onClick={() => paycodeSelection(item, 'audit')}
                                                                    >
                                                                    <i className="lni lni-list" style={{fontSize: "20px"}}></i> Audit Trail
                                                                    </Dropdown.Item>
                                                                    {
                                                                        item.locked ? (
                                                                            <>
                                                                                <Dropdown.Divider />
                                                                                <Dropdown.Item 
                                                                                    eventKey={2} 
                                                                                    className="d-flex align-items-center gap-1 text-primary"
                                                                                    onClick={ () => managePaycode(item, 'unlock') }
                                                                                >
                                                                                    <FontAwesomeIcon icon={"lock-open"} /> Unlock PayCode
                                                                                </Dropdown.Item>
                                                                            </>
                                                                        ) : 
                                                                        <>
                                                                            <Dropdown.Divider />
                                                                            <Dropdown.Item 
                                                                                eventKey={2} 
                                                                                className="d-flex align-items-center gap-1 text-danger"
                                                                                onClick={ () => managePaycode(item, 'lock') }
                                                                            >
                                                                                <FontAwesomeIcon icon={"lock"} /> Lock PayCode
                                                                            </Dropdown.Item>
                                                                        </>
                                                                    }
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </p>
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                        :
                                        (
                                            <tr>
                                                <td colSpan={5} className="text-center text-muted">No data found</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    {
                        paycodes?.length ? (
                            <div className="table-responsive mt-2">
                                <Paginator
                                    next={paginationData.next}
                                    prev={paginationData.prev}
                                    setCurrentPage={ handlePagination }
                                />
                            </div>
                        ) : null
                    }
                </div>

                <Modal
                    size="lg"
                    show={paycode_modal}
                    onHide={() => setPaycodeModal(false)}
                    backdrop='static'
                    className="border-0"
                >
                    <Modal.Header closeButton className="py-2 custom-card-header">
                        PayCode Details
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="justify-content-between">
                            <Col md={4} lg={6} sm={12}>
                                Reference: <p className="font-bold">{paycode.reference}</p>
                            </Col>
                            <Col md={4} lg={6} sm={12}>
                                Token: <p className="font-bold">{paycode.token}</p>
                            </Col>
                            <Col md={4} lg={6} sm={12}>
                                Amount: <p className="font-bold">{formatCurrency(paycode.amount).with_currency}</p>
                            </Col>
                            <Col md={4} lg={6} sm={12}>
                                Used: <p className="font-bold">{formatCurrency(paycode.used_amount).with_currency}</p>
                            </Col>
                            <Col md={4} lg={6} sm={12}>
                                Mobile: <p className="font-bold">{paycode.mobile}</p>
                            </Col>
                            <Col md={4} lg={6} sm={12}>
                                Payment Method: <p className="font-bold">{paycode.method}</p>
                            </Col>
                            <Col md={4} lg={6} sm={12}>
                                Date Created: <p className="font-bold">{paycode.date}</p>
                            </Col>
                            <Col md={4} lg={6} sm={12}>
                                Valid Till: <p className="font-bold">{paycode.validity}</p>
                            </Col>
                            <Col md={4} lg={6} sm={12}>
                                Created By: <p className="font-bold">{paycode.owner}</p>
                            </Col>
                            <Col md={4} lg={6} sm={12}>
                                Status: <p className="font-bold">{paycode.active ? 'Active' : 'Inactive'}</p>
                            </Col>
                            <Col md={4} lg={6} sm={12}>
                               {/* Lock Status:  */}
                               {
                                paycode.locked ? (
                                    <>
                                        <p className="text-danger m-0">This paycode is currently locked</p>
                                        <button 
                                            className="btn-plain btn text-white btn-sm bg-success ms-2"
                                            onClick={() => managePaycode(paycode, 'unlock')}
                                        >
                                            <FontAwesomeIcon icon={'lock-open'} />  Unlock here
                                        </button>
                                    </>
                                ) :
                                <>
                                    <p className="text-success m-0">This paycode is currently active</p>
                                    <button 
                                        className="btn-plain btn text-white btn-sm bg-danger ms-2"
                                        onClick={() => managePaycode(paycode, 'lock')}
                                    >
                                        <FontAwesomeIcon icon={'lock'} />  Lock here
                                    </button>
                                </>
                               }
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                <Modal
                    size="lg"
                    show={audit_modal}
                    onHide={() => setAuditModal(false)}
                    backdrop='static'
                    className="border-0"
                >
                    <Modal.Header closeButton className="py-2 custom-card-header">
                        PayCode Aduit Trail
                    </Modal.Header>
                    <Modal.Body>
                        <Table className="table" size="sm">
                            <thead>
                                <tr>
                                    <th>Reference</th>
                                    <th>Sender</th>
                                    <th>Amount</th>
                                    <th>Token</th>
                                    <th>Used By</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    audits.length ? (
                                        audits.map(auditItem => (
                                            <tr>
                                                <td>{auditItem.reference}</td>
                                                <td>{auditItem.sender}</td>
                                                <td>{formatCurrency(auditItem.amount).with_currency}</td>
                                                <td>{auditItem.token}</td>
                                                <td>{auditItem.used_by}</td>
                                                <td>{auditItem.date}</td>
                                                <td>{auditItem.status}</td>
                                            </tr>
                                        ))
                                    ) : 
                                    <tr className="border-0">
                                        <td colSpan={7} className="text-center">No data available</td>
                                    </tr>
                                }
                                <tr></tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                </Modal>
            </Container>
        </Layout>
    );
}

export default Paycode;