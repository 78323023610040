import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const SalesChart = ({ inflowData, outflowData }) => {
  const [timePeriod, setTimePeriod] = useState('monthly'); // Default time period

  const labels = {
    monthly: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    daily: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    hourly: Array.from({ length: 24 }, (_, i) => `${i}:00`),
  };

  const chartData = {
    labels: labels[timePeriod],
    datasets: [
      {
        label: 'Inflow',
        data: inflowData[timePeriod],
        borderColor: 'rgb(143, 50, 157)',
        backgroundColor: 'rgb(143, 50, 157, .2)',
        fill: true,
      },
      {
        label: 'Outflow',
        data: outflowData[timePeriod],
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        fill: true,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `Sales Data - ${timePeriod.charAt(0).toUpperCase() + timePeriod.slice(1)}`,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: timePeriod === 'hourly' ? 'Hour' : timePeriod === 'daily' ? 'Day' : 'Month',
        },
        grid: { display: false }
      },
      y: {
        title: {
          display: true,
          text: 'Amount',
        },
      },
    },
  };
  

  return (
    <div>
      <div>
        <button type='button' className='btn-plain rounded-1 bg---secondary' onClick={() => setTimePeriod('monthly')}>Monthly</button>
        <button type='button' className='btn-plain rounded-1 bg---secondary' onClick={() => setTimePeriod('daily')}>Daily</button>
        <button type='button' className='btn-plain rounded-1 bg---secondary' onClick={() => setTimePeriod('hourly')}>Hourly</button>
      </div>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default SalesChart;
