import { Container, Nav, Navbar, NavDropdown, Badge, Button } from "react-bootstrap";
import avatar from '../assets/images/avatar.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logout, sidebarToggle } from "../redux/slices/auth";
import { useDispatch, useSelector } from'react-redux'
import { getUserNotifications, markAsRead } from "../redux/slices/users";
import { useState, useEffect } from "react";
import { getMemberNotifications, markAsReadItem } from "../redux/slices/managers.slice";
import dateFormatter from "../utils/dateFormatter";
import { useNavigate } from "react-router-dom";

const Navigation = (props) => {
    
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { user, isAuthenticated, isManager, sidebar } = useSelector((state) => state.auth)
    const { business } = useSelector((state) => state.user)
    const [notifications, setNotifications] = useState([])
    const [show_notification, setShow] = useState(false)

    const signuOut = () => {
        dispatch(logout())
        .then(() => {
            navigate('/')
        })
    }

    const loadNotifications = () => {
        const method = isManager ?  getUserNotifications() : getMemberNotifications('False')
        dispatch(method)
        .then((res) => {
            if (res.payload?.results) {
                setNotifications(res.payload?.results)
            }
        })
    }

    const readNotification = (id) => {
        const method = isManager ? markAsRead(id) : markAsReadItem(id)
        dispatch(method)
        .then((res) => {
            if (res.payload?.success === true) {
                loadNotifications()
            }
        })
    }

    const menuToggle = () => {
        dispatch(sidebarToggle())
    }

    return (
      <Navbar collapseOnSelect expand="lg" bg="white" variant="light" className="shadow-sm" sticky="top" hidden={sidebar}>
        <Container md>
            <Button onClick={ menuToggle } className="me-auto d-block d-sm-none">
                <FontAwesomeIcon icon={'bars'} />
            </Button>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <h5>{props.title || ''}</h5>
                {/* <Nav className="me-auto">
                    {
                        business?.business_type === 'Starter Business' ? (
                            <div className="navbar-notification">
                                <p>
                                    You are on a Starter business, Kindly upgrade to avoid limitations on your transaction 
                                </p>
                            </div>
                        ) : null
                    }
                </Nav> */}
            </Navbar.Collapse>
            <Navbar.Collapse className="justify-content-end">
                <Nav>
                    <Nav.Link onClick={() => setShow(!show_notification)} className="custom-bordered p-2 me-2 notification-button">
                        <FontAwesomeIcon icon="far fa-bell" style={{ fontSize: 25, color: "#828282" }} className="text-center" />
                        <sup>
                            <Badge 
                                className="badge badge-danger rounded-circle" 
                                style={{
                                    height: "25px", width: "25px", 
                                    position: "absolute", right: "-14px", top: "-10px", 
                                    display: "flex", alignItems: "center", 
                                    justifyContent: "center", fontWeight: "600",
                                    fontSize: "14px"
                                }}
                            >
                                {notifications && notifications.length}
                            </Badge>
                        </sup>    
                        {
                            show_notification ? (
                                <div className="notification-dropdown">
                                    <h6>Notifications</h6>
                                    <hr/>
                                    <ul>
                                        {
                                            notifications && notifications.map((item, index) => ( 
                                                !item.read ? (
                                                    <li key={index}>
                                                        <button 
                                                            type="button" 
                                                            className="btn-plain"
                                                            onClick={() => readNotification(item.id)}
                                                        >
                                                            <strong className="mb-0">{item.title}</strong>
                                                            <p className="m-0">{item.message}</p>
                                                            <p className="text-right m-0 time">{dateFormatter(item.date)}</p>
                                                        </button>
                                                    </li>
                                                ) : null
                                            ))
                                        }
                                    </ul>
                                </div>
                            ) : null
                        }                    
                    </Nav.Link>
                    <NavDropdown 
                        id="nav-dropdown"
                        className="custom-bordered dropdown-menu-lg-start"
                        title={
                            <span className="d-inline-flex align-items-center">
                                <span className="nav-avatar">
                                    <img
                                        className="thumbnail-image rounded-circle" 
                                        src="https://via.placeholder.com/60"
                                        alt="avatar"
                                    />
                                </span>
                                <span className="px-2">
                                    {
                                        user && isAuthenticated ? (
                                            <>{user.first_name+' '+user.last_name}</>
                                        )
                                        : null
                                    }
                                </span>
                            </span>
                        } 
                    >
                        {/* {
                            isManager ? (
                                <>
                                    <NavDropdown.Item href="/settings">
                                        <FontAwesomeIcon icon='fa fa-cog' style={{fontSize: "20px"}}/> Account Setting
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="/customers" className="text-success">
                                        <i className="lni lni-users" style={{fontSize: "25px"}}></i>
                                        <span className="px-2">My Managers</span>
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="subscriptions" className="text-secondary">
                                        <i className="lni lni-database"></i> <span className="px-1">My Subscription</span>
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="/settings" className="text-primary">
                                        <i className="lni lni-printer"></i> <span className="px-1">Print Banner</span>
                                    </NavDropdown.Item>
                                </>
                            ) : (
                                <> */}
                                    <NavDropdown.Item href="/managers/change-password" className="text-primary">
                                        {/* <i className="lni lni-printer"></i>  */}
                                        <FontAwesomeIcon icon="lock"/>
                                        <span className="px-1">Change Password</span>
                                    </NavDropdown.Item>
                                {/* </>
                            )
                        } */}
                        <NavDropdown.Divider />
                        <NavDropdown.Item 
                            className="text-primary"
                            onClick={signuOut}
                        >
                            <FontAwesomeIcon icon='fa fa-right-from-bracket' style={{fontSize: "20px"}}/> <span className="px-1">Logout</span>
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Container>
      </Navbar>
    );
}

export default Navigation;