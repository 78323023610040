import { createSlice } from "@reduxjs/toolkit";

const initialState = {name: 'dashboard', status: true};

const menuSlice = createSlice({
  name: "menuu",
  initialState,
  reducers: {
    setMenu: (state, action) => {
      return { menu: action.payload };
    },
    clearMenu: () => {
      return { menu: "" };
    },
  },
});

const { reducer, actions } = menuSlice;

export const { setMenu, clearMenu } = actions
export default reducer;