const dateFormatter = (timestamp) => {
  // Check if the timestamp is a valid number

  if(timestamp === undefined || timestamp === '') {
    return '';
  }
  
  const date = new Date(timestamp);

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  //   second: 'numeric',
  //   timeZoneName: 'short',
  //   timeZone: 'africa/lagos'
  };

  const formatter = new Intl.DateTimeFormat('en-GB', options);

  return formatter.format(date);
  
}

export default dateFormatter;
