import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

// slices
import authReducer from './slices/auth';
import messageReducer from './slices/message';
import userReducer from './slices/users';
import accountReducer from './slices/account.service';
import p2pReducer from './slices/p2p.slice';
import supportReducer from './slices/support.slice';
import menuReducer from './slices/menu.nav.slice';
import memberReducer from './slices/managers.slice';
import memberAuthReducer from './slices/manager.auth.slice';
import BillsReducer from './slices/billspayment.slice';

// ----------------------------------------------------------------------

const createNoopStorage = () => ({
  getItem() {
    return Promise.resolve(null);
  },
  setItem(_key, value) {
    return Promise.resolve(value);
  },
  removeItem() {
    return Promise.resolve();
  },
});

const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const authPersistConfig = {
  key: 'auth',
  storage,
  keyPrefix: 'redux-',
};

const businessPersistConfig = {
  key: 'business',
  storage,
  keyPrefix: 'redux-',
};

const rootReducer = combineReducers({
  message: messageReducer,
  menu: menuReducer,
  member: memberReducer,
  member_auth: persistReducer(authPersistConfig, memberAuthReducer),
  user: persistReducer(businessPersistConfig, userReducer),
  account: persistReducer(businessPersistConfig, accountReducer),
  p2p: p2pReducer,
  support: persistReducer(businessPersistConfig, supportReducer),
  auth: persistReducer(authPersistConfig, authReducer),
  bills: BillsReducer,
});

export { rootPersistConfig, rootReducer };
