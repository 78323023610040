import { Row, Col, Container, Card, Spinner, Button, Table, Badge, Dropdown, Modal } from "react-bootstrap";
import Layout from "../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../components/table/Paginator";
import { useEffect, useState } from "react";
import DrewsModal from "../components/modal/DrewsModal";
import { useDispatch } from "react-redux";
import { getTransactionHistory } from "../redux/slices/account.service";
import formatCurrency from "../utils/currencyFormmter";
import Logo from "../components/Logo";
import dateFormatter from "../utils/dateFormatter";
import { useSearchParams } from "react-router-dom";
import RecordFilter from "../components/RecordFilter";

const Transactions = () => {


    const dispatch = useDispatch()

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const [searchParams, setSearchParams] = useSearchParams()
    const [activeTab, setActiveTab] = useState('all')
    const [modalShow, setModalShow] = useState(false)
    const [transaction, setTransaction] = useState({})
    const [transactions, setTransactions] = useState([])
    const [results, setResults] = useState([])
    const [analytics, setAnalytics] = useState({})
    const [receipt, setReceipt] = useState({})
    const [show_receipt, setShowReceipt] = useState(false)
    const [copiedText, setCopiedText] = useState('');
    const [paginationData, setpaginationData] = useState({next: '', prev: ''})
    const [loading, setLoading] = useState(false)
    const [payload, setPayload] = useState({
        page: 1,
        merchant: '',
        from_date: '',
        to_date: '',
        status: '',
        download: '',
        filter_by: '',
        sort_by: '',
        filter: '',
        gateway: '',
    })
  

    // useEffect(() => {
    //     if(searchParams.get('merchant')) {
    //         setPayload({...payload, merchant: searchParams.get('merchant')})
    //     }
    // }, [0])

    useEffect(() => {
        loadTransactions()
    }, [payload.page, payload.status])

    const selectTransaction = (id) => {
        const transaction = transactions.filter(item => item.reference === id)[0]
        if(transaction.note == 'Electricity') {
            setReceipt(transaction);
            setShowReceipt(true)
        }
        else {
            setTransaction(transaction)
            setModalShow(true)
        }
    }

    const loadTransactions = () =>  {
        setLoading(true)
        dispatch(getTransactionHistory(payload))
        .then((response) => {
            if(response.payload.results) {
                setTransactions(response.payload.results)
                setResults(response.payload.results)
            }
            if(response.payload?.analytics) {
                setAnalytics(response.payload.analytics)
            }

            if(response.payload?.next || response.payload?.previous) {
                const data = {
                    next: response.payload?.next,
                    prev: response.payload?.previous,
                }
                setpaginationData(data)
            }
        })
        .finally(() => setLoading(false))
    }

    const filterTransactions  = (value) => {
        setActiveTab(value)
        setPayload({...payload, status: value, page: 1})
    }

    const handleCopy = (copyText) => {
        navigator.clipboard.writeText(copyText)
          .then(() => {
            setCopiedText(copyText);
          })
          .catch(err => {
            console.error('Error copying text: ', err);
          });
    };

    const handlePagination = (data) => {
        setPayload({...payload, page: data})
    }

    const handleSearch = () => {
        loadTransactions()
    }

    const handleInput = (event) => {
        const {name, value} = event.target
        setPayload((prevState)=> ({...prevState,[name]:value}))
    }

    const toggleTabs = (option) => {
        setActiveTab(option)
        if(option === 'all') {
            setPayload({...payload, status: ''})
        }
        else {
            setPayload({...payload, status: option})
        }
    }
 
    return (
        <Layout title="Transactions">
            <Container className="mt-4 px-4 mb-4">
                    <Row className="mb-3">
                        <Col md={12} className="mb-3 d-flex gap-2 wrap table-responsive">
                            {/* <div className=""> */}
                                <Button 
                                    type="button" 
                                    variant="light" 
                                    className="tab-buttons"
                                    active={activeTab === 'all' ? true : false}
                                    onClick={ () => toggleTabs('all')}
                                >
                                    All Transactions
                                </Button>
                                <Button 
                                    type="button" 
                                    variant="light" 
                                    className="tab-buttons"
                                    active={activeTab === 'success' ? true : false}
                                    onClick={ () => toggleTabs('success') }
                                >
                                    Successful Transactions
                                </Button>
                                <Button 
                                    type="button" 
                                    variant="light" 
                                    className="tab-buttons"
                                    active={activeTab === 'pending' ? true : false}
                                    onClick={ () => toggleTabs('pending') }
                                >
                                    Pending Transactions
                                </Button>
                                <Button 
                                    type="button" 
                                    variant="light" 
                                    className="tab-buttons"
                                    active={activeTab === 'failed' ? true : false}
                                    onClick={ () => toggleTabs('failed') }
                                >
                                    Failed Transactions
                                </Button>
                            {/* </div> */}
                        </Col>
                    </Row>

                <div className="mt-4">
                    {
                        loading ? (
                            <div className="page-loader">
                                <Spinner size="lg" variant="primary"/>
                            </div>
                        ) : null
                    }
                    {
                        !payload.merchant ? (
                            <Card className="mb-3 border-0 shadow-sm">
                                <Card.Body>
                                    <div className="d-flex justify-content-between">
                                        <h5>Overview</h5>
                                        <div className="d-flex justify-content-end">
                                            <p>
                                                <select className="form-control">
                                                    {
                                                        months.length ? (
                                                            months.map((month, index) => (
                                                                <option value={month.toLowerCase()} key={index}>{month}</option>
                                                            ))
                                                        ) : null
                                                    }
                                                </select>
                                            </p>
                                            {/* <p>
                                                <Button variant="outline-primary" type="button" className="ms-3">
                                                    View chart
                                                </Button>
                                            </p> */}
                                        </div>
                                    </div>
                                    <div className="mb-3 mt-3 analytic-row">
                                        <div className="shadow-sm card bg--primary" onClick={() => toggleTabs('all')}>
                                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                                <div className="icon ">
                                                </div>
                                                <div>
                                                    <h3 className="m-0 text-primary font-bold">{analytics?.total_transactions || '0'}</h3>
                                                    <p className="text-muted text-sm m-0">Total Transactions</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="shadow-sm card" onClick={() => toggleTabs('success')}>
                                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                                <div className="icon bg-light">
                                                </div>
                                                <div>
                                                    <h3 className="m-0 text-success font-bold">{analytics?.total_successful_transactions || '0'}</h3>
                                                    <p className="text-muted text-sm m-0">Successful Transactions</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="shadow-sm card" onClick={() => toggleTabs('failed')}>
                                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                                <div className="icon">
                                                </div>
                                                <div>
                                                    <h3 className="m-0 text-danger font-bold">{analytics?.total_failed_transactions || '0'}</h3>
                                                    <p className="text-muted text-sm m-0">Total Failed Payments</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="shadow-sm card" onClick={() => toggleTabs('pending')}>
                                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                                <div className="icon">
                                                </div>
                                                <div>
                                                    <h3 className="m-0 text-warning font-bold">{analytics?.total_pending_transactions || '0'}</h3>
                                                    <p className="text-muted text-sm m-0">Total Pending Withdrawals</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="shadow-sm card" onClick={() => toggleTabs('failed')}>
                                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                                <div className="icon">
                                                </div>
                                                <div>
                                                    <h3 className="m-0 text-secondary font-bold">{analytics?.total_failed_transactions || '0'}</h3>
                                                    <p className="text-muted text-sm m-0">Total Failed Transfers</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="shadow-sm card" onClick={() => toggleTabs('faspay')}>
                                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                                <div className="icon">
                                                </div>
                                                <div>
                                                    <h3 className="m-0 text-primary font-bold">{analytics?.total_fx_paycode_transactions || '0'}</h3>
                                                    <p className="text-muted text-sm m-0">Total FX Paycode Transactions</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="shadow-sm card" onClick={() => toggleTabs('faspay')}>
                                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                                <div className="icon">
                                                </div>
                                                <div>
                                                    <h3 className="m-0 text-success font-bold">{analytics?.total_usd_paycode_transactions || '0'}</h3>
                                                    <p className="text-muted text-sm m-0">Total USD Paycode Transactions</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="shadow-sm card" onClick={() => toggleTabs('faspay')}>
                                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                                <div className="icon">
                                                </div>
                                                <div>
                                                    <h3 className="m-0 text-primary font-bold">{analytics?.total_eur_paycode_transactions || '0'}</h3>
                                                    <p className="text-muted text-sm m-0">Total EUR Paycode Transactions</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="shadow-sm card" onClick={() => toggleTabs('faspay')}>
                                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                                <div className="icon">
                                                </div>
                                                <div>
                                                    <h3 className="m-0 text-dark font-bold">{analytics?.total_cad_paycode_transactions || '0'}</h3>
                                                    <p className="text-muted text-sm m-0">Total CAD Paycode Transactions</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="shadow-sm card" onClick={() => toggleTabs('faspay')}>
                                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                                <div className="icon">
                                                </div>
                                                <div>
                                                    <h3 className="m-0 text-secondary font-bold">{analytics?.total_gbp_paycode_transactions || '0'}</h3>
                                                    <p className="text-muted text-sm m-0">Total GBP Paycode Transactions</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        ) : null
                    }

                    <RecordFilter
                        handleInput={handleInput}
                        handleSearchSubmit={handleSearch}
                    />

                    <Card className="border-0 shadow-sm">
                        <Card.Body className="table-responsive">
                            <Table size="sm" className="table-color table-stripedd">
                                <thead>
                                    <tr>
                                        <th>Details</th>
                                        <th>Business Name</th>
                                        <th>Order</th>
                                        <th>Amount</th>
                                        <th>Balance</th>
                                        {/* <th>Date Created</th> */}
                                        <th>Type</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        results?.length ? (
                                            results?.map((item, index) => (
                                                <tr key={index+3}>
                                                    <td>
                                                        <div className="m-0 d-flex gap-2 align-items-center">
                                                            <small className={item.status === 'success' ? 'drews-badge drews-badge-success-circle' : 'drews-badge drews-badge-error-circle'}>
                                                                <i className="lni lni-arrow-top-right"></i>
                                                            </small>
                                                            <strong>
                                                                <p className="m-0">{item.note}</p>  
                                                                <small>{dateFormatter(item.date)}</small>
                                                            </strong>
                                                        </div>
                                                    </td>
                                                    <td> {item.business_name}  </td>
                                                    <td> {item.order}  </td>
                                                    <td>{formatCurrency(item.amount).with_currency}</td>
                                                    <td>{formatCurrency(item.amount_after).with_currency} </td>
                                                    {/* <td>
                                                        {item.date}
                                                    </td> */}
                                                    <td>
                                                        <div className={`text-center font-bold snack-badge ${item.transaction_type == 'Electricity' ?
                                                             'bg-type-secondary' : (item.transaction_type == 'Withdrawal' ? 
                                                             'bg-type-danger' :  (item.transaction_type == 'Cable Tv' ? 
                                                             'bg-type-warning' :  (item.transaction_type == 'Bill Payment' ? 
                                                             'bg-type-primary' : 'bg-type-light')))}`}>
                                                            {item.transaction_type}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex m-0 align-items-center justify-content-between">
                                                            <Badge 
                                                                className={item.status === 'success' ? 'badge-active' : 
                                                                (item.status === 'failed' ? 'badge-failed' : 
                                                                (item.status === 'used' ? 'badge-used' : 'badge-pending'))}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                    <circle cx="5" cy="5" r="5" fill={
                                                                        item.status === 'success' ? "#008000" : 
                                                                        (item.status === 'failed' ? "#EB5757" : 
                                                                        (item.status === 'used' ? "#828282" : "#FAB941"))
                                                                    }
                                                                    />
                                                                </svg>
                                                                <span className="ps-1">
                                                                    {item.status}
                                                                </span>
                                                            </Badge> 
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="light" className="border-0 bg-transparent" size="sm" id="dropdown-basic">
                                                                    <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                </Dropdown.Toggle>
    
                                                                <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                    <Dropdown.Item 
                                                                        eventKey={1} 
                                                                        className="text-primary d-flex align-items-center gap-1" 
                                                                        onClick={() => selectTransaction(item.reference)}
                                                                    >
                                                                    <i className="lni lni-eye" style={{fontSize: "20px"}}></i>  View Details
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item 
                                                                        eventKey={2} 
                                                                        className="text-danger d-flex align-items-center gap-1"
                                                                        onClick={ () => selectTransaction(item.reference) }
                                                                    >
                                                                        <i className="lni lni-question-circle"></i> Report Transaction
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                        : 
                                        (
                                            <tr>
                                                <td colSpan={5} className="text-center text-muted">No data found</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    {
                        results?.length ? (
                            <div className="table-responsive mt-2">
                                <Paginator
                                    next={paginationData.next} 
                                    prev={paginationData.prev}
                                    setCurrentPage={ handlePagination }
                                />
                            </div>
                        ) : null
                    }
                </div>
                <DrewsModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    dismissable={true}
                    title={transaction.reference}
                    badge_text={transaction.type}
                >
                    <Row>
                        <Col md={6}>
                            <div className="modal-profile">
                                <ul>
                                    <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="text-muted">Transaction ID</small>
                                                {transaction.reference} 
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="text-muted">Note</small>
                                                {transaction?.note || 'Nil'} 
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="text-muted">Beneficiary Account</small>
                                                {transaction?.balance || 'Nil'}
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="text-muted">Status</small>
                                                <span 
                                                className={transaction.status === 'success' ? 'text-success' : 
                                                (transaction.status === 'failed' ? 'text-danger' : 
                                                (transaction.status === 'used' ? 'text-dark' : 'text-warning'))}
                                                >
                                                    {transaction.status}
                                                </span> 
                                            </p>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </Col>
                        <Col md={6} className="modal-profile">
                            <ul>
                                <li>
                                    <div>
                                        <p className="pt-2">
                                            <small className="text-muted">Amount</small>
                                            {formatCurrency(transaction.amount).with_currency}
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <p className="pt-2">
                                            <small className="text-muted">Transaction Date</small>
                                            {transaction.date}
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </DrewsModal>

                <Modal 
                    show={show_receipt} 
                    fullscreen={true} 
                    onHide={() => setShowReceipt(false)}
                    dialogClassName="receipt-screen-modal"
                    backdrop="static"
                >
                    <Modal.Header>
                        <button className="btn-plain">
                            <FontAwesomeIcon icon="chevron-left" onClick={ () => setShowReceipt(false) }/>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="p-2">
                        <div className="invoice px-3 py-0 border border-0">
                            <div className="border-bottom border-bottom-1 d-flex align-items-center justify-content-between pb-2 py-2">
                                <Logo variant="dark"/>
                                <div>
                                    <h5 className="mb-0 text-right">Transaction Receipt</h5>
                                    <p className="text-muted text-sm m-0 text-right">Generated: {receipt.date != null ? dateFormatter(receipt.date) : ''}</p>
                                </div>
                            </div>
                            <div className="table-responsive mb-3">
                                <table className="table border-0">
                                    <tbody>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Transaction Amount
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {formatCurrency(receipt.amount).with_currency}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Transaction Type
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {receipt?.bill_type}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Service Provider
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {receipt?.provider || 'Nill'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Date
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {receipt.date != null ? dateFormatter(receipt.date) : ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Meter No:
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {receipt?.meterNo || 'Nill'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Token
                                            </td>
                                            <td className="text-primary border-0 text-right copy-text">
                                                {receipt?.tokenCode || 'Nill'} 
                                                <button className="btn-plain">
                                                    <FontAwesomeIcon icon={"copy"} className="text-success" onClick={ ()  => handleCopy(receipt?.tokenCode || 'Nill') }/>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Transaction ID
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {receipt?.order}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </Container>
        </Layout>
    );
}

export default Transactions;