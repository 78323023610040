import { Menu, MenuItem, SubMenu, Sidebar } from "react-pro-sidebar";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container} from "react-bootstrap";
import logo from '../assets/images/logo_white.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { menus } from "../utils/menu";
import { setMenu } from "../redux/slices/menu.nav.slice";
import { logout, sidebarToggle } from "../redux/slices/auth";

const AppSidebar = () => {

    const dispatch = useDispatch()

    const navigate = useNavigate()
    
    const { menu } = useSelector((state) => state.menu)
    const { isAuthenticated, sidebar } = useSelector((state) => state.auth)

    const [menu_item, setActiveMenu] = useState(menu?.name)

    useEffect(() => {
        if(menu?.name) {
            setActiveMenu(menu.name)
        }
    },[0])

    const menuClasses = {
        button: 'ps-menuitem-root', 
        subMenuContent: 'ps-submenu-content', 
        menuButton: 'ps-menu-button'
    }

   const handleNavigation = (item) => {
        dispatch(setMenu({name: item, status: true}))
   }

   const handleLogout = () => {
    dispatch(logout())
    .then(() => {
        navigate('/')
    })
   }

   const menuToggle = () => {
        dispatch(sidebarToggle())
    }

    return (
        <div className={`sidebar-plain`}>
            <Sidebar 
                backgroundColor="#443792" 
                className="no-print sdidebar" 
                onBackdropClick={ menuToggle } 
                toggled={sidebar} 
                breakPoint="sm"
            >
                <div className="text-center py-2 mb-3 sidebar-logo">
                    <img src={logo} alt="logo" />
                </div>
                <div className="px-2 pb-4" style={{ height: '90vh', minHeight: '90vh'}}>
                    <Menu
                        menuItemStyles={{
                            button: ({ level, active, disabled }) => {
                                return {
                                    color: active ? '#443792' : '#ffffff',
                                    backgroundColor: active ? '#F7F6FF' : '#443792',
                                    borderRadius: "8px",
                                    '&:hover': {
                                        backgroundColor: '#F7F6FF',
                                        borderRadius: "8px",
                                        color: "#443792"
                                    },
                                };
                            },
                        }}
                    >
                        {
                            menus.length && isAuthenticated ? (
                                menus.map((item, index) => (
                                    item.children ? 
                                        <SubMenu 
                                            key={index}
                                            label={item.name}
                                            rootStyles={{ 
                                                borderTop: "1px solid rgba(242, 242, 242, 0.20)",
                                                ['& > .' + menuClasses.button]: {
                                                    backgroundColor: '#eaabff',
                                                    color: '#f2f2f2',
                                                    '&:hover': {
                                                        backgroundColor: '#eecef9',
                                                    },
                                                    },
                                                ['.' + menuClasses.subMenuContent]: {
                                                    backgroundColor: 'transparent',
                                                },
                                            }}
                                            defaultOpen={ item.children.filter(itm => itm.alias.includes(menu_item)).length }
                                        >
                                            {
                                                item.children.length ? (
                                                    item.children.map((childItem, childIndex) => (
                                                        <MenuItem
                                                            key={childIndex}
                                                            icon={ 
                                                                childItem.icon_type === 'lni' ?  
                                                                <i className={childItem.icon} style={{fontSize: 20}}></i> :
                                                                    childItem.icon_type === 'fa' ? 
                                                                    <FontAwesomeIcon icon={childItem.icon} style={{ fontSize: 20 }} /> :
                                                                    childItem.icon_type === 'svg' ? <img src={require(`../assets/images/${childItem.icon}.svg`)} alt={childItem.name} /> : null
                                                            } 
                                                            component={<Link to={childItem.url}/>}
                                                            rootStyles={{
                                                                ['.' + menuClasses.menuButton]: {
                                                                    padding: "0px",
                                                                },
                                                            }}
                                                            onClick={() => handleNavigation(childItem.alias)}
                                                            active={ menu_item === childItem.alias }
                                                        >
                                                            {childItem.name}
                                                        </MenuItem>

                                                    )) 
                                                ) : null
                                            }
                                        </SubMenu>
                                    :
                                        <MenuItem 
                                            key={index}
                                            component={<Link to={item.url}/>}
                                            icon={ 
                                                item.icon_type === 'lni' ?  
                                                <i className={item.icon} style={{fontSize: 20}}></i> : 
                                                <FontAwesomeIcon icon={item.icon} style={{ fontSize: 20 }} />
                                            } 
                                            active={ menu_item === item.alias || menu_item === null }
                                            onClick={() => handleNavigation(item.alias)}
                                        >
                                            {item.name}
                                        </MenuItem>
                                ))
                            ) 
                            : null
                        }
                    </Menu>
                </div>
                <div className="logout-section">
                    <Button 
                        type="button"
                        variant="light" 
                        className="font-bold text-danger"
                        onClick={handleLogout}
                    >
                        <FontAwesomeIcon icon="sign-out"/>
                    <span className="ms-2">Logout</span>  
                    </Button>
                </div>
            </Sidebar>

        </div>
    );
}


export default AppSidebar;