
import ServiceHelper from "./service.helper";


export default class AuthService {

  static API_URL = '/adminapi';
  
  static async signup(payload) {
    return await ServiceHelper.handlePostRequest(this.API_URL+"/user/", payload);
  };
  
  static async verification(payload) {
    return await ServiceHelper.handlePostRequest(this.API_URL+"/verify/", payload);
  };
  
  static async resendOtp(payload) {
    return await ServiceHelper.handlePostRequest(this.API_URL+"/re_verify/", payload);
  };
  
  static  async login (payload) {
    return await ServiceHelper.handlePostRequest(this.API_URL+"/login", payload)
  };
  
  static  async forgotPassword (payload) {
    return await ServiceHelper.handlePostRequest(this.API_URL+"/forgot-password/", payload)
  };
  
  static  async resetPassowrd (payload) {
    return await ServiceHelper.handlePatchRequest(this.API_URL+"/forgot_password_validate/", payload)
  };
  
  static  async logout() {
      localStorage.removeItem("token")
    return await localStorage.removeItem("user");
  };

  static createBusiness(payload) {
    return ServiceHelper.handlePostRequest("/create_business/", payload, {headers:{'Content-Type': 'multipart/form-data'}});
  }

  static requestPinChange() {
    return ServiceHelper.handleGetRequest("/pin_update/");
  }

  static confirmPinChange(payload) {
    return ServiceHelper.handlePostRequest("/pin_update/", payload);
  }

}
