import { Col, Container, Form, Row, Button, Card, Spinner, FormGroup } from "react-bootstrap";
import Logo from "../../components/Logo";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { useState } from 'react';
import { useDispatch } from "react-redux";
import { createPaycode, payInvoice } from "../../redux/slices/account.service";
import Swal from "sweetalert2";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import formatCurrency from "../../utils/currencyFormmter";

const UniversalPaycode = () => {

    const conf = {
        public_key: 'FLWPUBK_TEST-5ac81c972b9cbd4ee32e9f30487a00c5-X',
        tx_ref: Date.now(),
        amount: 0,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd',
        customer: {
          email: '',
          phone_number: '',
          name: '',
        },
        customizations: {
          title: 'WallX Business Paycode Payment',
          description: 'Funding the paycode you generated',
          logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
        },
    }

    const [loading, setLoading] = useState(false)
    const [payload, setPayload] = useState({})
    const [errors, setErrors] = useState({})
    const [config, setConfig] = useState(conf)

    const publicKey = 'pk_test_741bc055b3cfde6f6c5244d89a38b99532d13ea2'

    const dispatch = useDispatch();


      const handleFlutterPayment = useFlutterwave(config);

    const handleInput = (event) => {
        const {name, value} = event.target
        if(name === 'dob') {
            const dateParts = value.split('-');
            const dob = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
            setPayload((prevState) => ({...prevState, [name]: dob}))
        }
        else {
            setPayload((prevState) => ({...prevState, [name]: value}))
        }
    }

    const handleValidation = (event) => {

        const regex = /^\d*\.?\d*$/;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    
        const {name, value} = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if(name === 'email' && !pattern.test(value)) {
            setErrors((prevState) => ({ ...prevState, [name]: 'Please enter a valid email address'}))
        }
        else if (name ==='amount' && !regex.test(value)) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }
    }

    const payWithPaycode = () => {
        if (!payload.paycode || !payload.amount) return;
        setLoading(true)
        dispatch(payInvoice)
        .then((res) => {
            console.log("INVOICE", res)
        })
        .finally(() => setLoading(false))
    }

    const createNewPaycode = () => {
        let new_config = config

        new_config.customer.email = payload.email
        new_config.customer.phone_number = payload.mobile
        new_config.amount = payload.amount
        
        setConfig(new_config)
        
        setLoading(true)
        dispatch(createPaycode(payload))
        .then((response) => {
            if(response.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message,
                    showConfirmButton: true, 
                    confirmButtonText: 'Pay now',
                    allowOutsideClick: false
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        window.open(response.payload?.data.link, '_blank')
                    }
                })
            }
            if(response.payload?.success === 'False') {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }
    
    return (
        <Row className="full-height justify-content-center">
            <Col md={10} lg={6} sm={12} className="px-5">
                <Container className="mt-5 text-center px-5">
                    <div className="mt-3 mb-5">
                        <Logo variant="dark"/>
                    </div>
                </Container>
                <Container className="px-5 px-md-5 mt-5">
                    <Card className="border-0 shadow">
                        <CardHeader className="justify-content-between d-flex">
                            <h5>Payment</h5>
                        </CardHeader>
                        <Card.Body>
                        <Form>
                            <p className="text-sm">Generate new paycode.</p>
                            <Row>
                                <Form.Group as={Col} md={12} className="mb-2">
                                    <Form.Label className="mb-0">Mobile Number</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        required
                                        placeholder="Mobile number"
                                        name="mobile"
                                        onChange={handleInput}
                                        onKeyUp={handleValidation}
                                        maxLength={11}
                                        minLength={11}
                                    />
                                    {
                                        errors['mobile'] && 
                                        <Form.Text className="text-danger">{errors['mobile']}</Form.Text>
                                    }
                                </Form.Group>
                                
                                <Form.Group as={Col} md={12} className="mb-2">
                                    <Form.Label className="mb-0">Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        required
                                        placeholder="Email"
                                        name="email"
                                        onChange={handleInput}
                                        onKeyUp={handleValidation}
                                    />
                                    {
                                        errors['email'] && 
                                        <Form.Text className="text-danger">{errors['email']}</Form.Text>
                                    }
                                </Form.Group>


                                <Form.Group as={Col} md={12} className="mb-2">
                                    <Form.Label className="mb-0">Amount</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        placeholder="0.00"
                                        name="amount"
                                        onChange={handleInput}
                                        onKeyUp={handleValidation}
                                        value={ parseFloat(payload.amount) > 0 ? formatCurrency(payload.amount).formatted : ''}
                                    />
                                    {
                                        errors['amount'] && 
                                        <Form.Text className="text-danger">{errors['amount']}</Form.Text>
                                    }
                                </Form.Group>

                                <Form.Group as={Col} md={12} className="mb-2">
                                    <Form.Label className="mb-0">BVN</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        placeholder="BVN"
                                        name="bvn"
                                        onChange={handleInput}
                                        onKeyUp={handleValidation}
                                        maxLength={11}
                                        minLength={11}
                                    />
                                    {
                                        errors['bvn'] && 
                                        <Form.Text className="text-danger">{errors['bvn']}</Form.Text>
                                    }
                                </Form.Group>

                                <Form.Group as={Col} md={12} className="mb-2">
                                    <Form.Label className="mb-0">Date of Birth</Form.Label>
                                    <Form.Control
                                        type="date"
                                        required
                                        placeholder="Date of Birth"
                                        name="dob"
                                        onChange={handleInput}
                                        onKeyUp={handleValidation}
                                    />
                                    {
                                        errors['dob'] && 
                                        <Form.Text className="text-danger">{errors['dob']}</Form.Text>
                                    }
                                </Form.Group>
                                
                                <Form.Group as={Col} md={12} className="mb-2">
                                    <Form.Label className="mb-0">Secret Word</Form.Label>
                                    <Form.Control
                                        type="password"
                                        required
                                        placeholder="Secret Word"
                                        name="secret"
                                        onChange={handleInput}
                                        onKeyUp={handleValidation}
                                        maxLength={6}
                                        minLength={6}
                                    />
                                    {
                                        errors['secret'] && 
                                        <Form.Text className="text-danger">{errors['secret']}</Form.Text>
                                    }
                                </Form.Group>

                                <Form.Group 
                                    as={Col} 
                                    md={12} 
                                    controlId="validationCustom011" 
                                    className="mt-4 d-grid"
                                >
                                    <Button 
                                        variant="primary" 
                                        size="lg" 
                                        onClick={createNewPaycode}
                                        disabled={Object.keys(errors).length > 0}
                                    >
                                        Generate PayCode <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                    </Button>
                                </Form.Group>
                            </Row>
                        </Form> 
                        </Card.Body>
                    </Card>
                </Container>
            </Col>
        </Row>
    );
}


export default UniversalPaycode;