import { Row, Col, Container, Card, Button, Table, Badge, Dropdown, Form, Spinner, Modal } from "react-bootstrap";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../../components/table/Paginator";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPaycodes, lockUnlockPaycode, getAudits, getLoyaltyPaycodeHistory, loyaltyPaycodeCharge } from "../../redux/slices/account.service";
import Swal from "sweetalert2";
import formatCurrency from "../../utils/currencyFormmter";
import dateFormatter from "../../utils/dateFormatter";
import RecordFilter from "../../components/RecordFilter";

const LoyaltyPaycode = () => {

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth)

    const [paycode_modal, setPaycodeModal] = useState(false)
    const [audit_modal, setAuditModal] = useState(false)
    const [payload, setPayload] = useState({
        page: 1,
        from_date: '',
        to_date: '',
        status: '',
        download: '',
        filter_by: '',
        sort_by: '',
        filter: '',
        gateway: '',
    })
    const [loading, setLoading] = useState(false)
    const [paycodes, setPaycodes] = useState([])
    const [errors, setErrors] = useState({})
    const [analytics, setAnalytics] = useState({})
    const [audits, setAudits] = useState([])
    const [paycode, setPaycode] = useState({})
    const [paginationData, setpaginationData] = useState({next: '', prev: ''})
    const [modalShow, setModalShow] = useState(false)
    const [paycodeHistory, setPaycodeHistory] = useState([])
    const [setCharge, setCharges] = useState(false)

    useEffect(() => {
        loadPaycodes()
    }, [payload.page])

    // useEffect(() => {
    //     loadVoucherHistory()
    // },[payload.paycode_id])

    const handleInput = (event) => {
        const {name, value} = event.target
        if(name === 'dob') {
            const dateParts = value.split('-');
            const dob = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
            setPayload((prevState) => ({...prevState, [name]: dob}))
        }
        else if(name === 'amount') {
            const newValue = parseFloat(value.replace(/[^\d.]/g, ''))
            setPayload((prevState)=> ({...prevState,[name]:newValue}))
        }
        else {
            setPayload((prevState) => ({...prevState, [name]: value}))
        }
    }

    const handleValidation = (event) => {

        const regex = /^\d*\.?\d*$/;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    
        const {name, value} = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if(name === 'email' && !pattern.test(value)) {
            setErrors((prevState) => ({ ...prevState, [name]: 'Please enter a valid email address'}))
        }
        else if (name ==='amount' && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }
    }

    const loadVoucherHistory = () => {
        setLoading(true)
        dispatch(getLoyaltyPaycodeHistory(payload))
        .then((response) => {
            if(response.payload?.results) {
                setPaycodeHistory(response.payload.results)
            }
        })
        .finally(() => setLoading(false))
    }

    const loadPaycodes = () => {
        setLoading(true)
        dispatch(getLoyaltyPaycodeHistory(payload))
        .then((response) => {
            if(response.payload?.results) {
                setPaycodes(response.payload.results)
            }
            if(response.payload?.analytics) {
                setAnalytics(response.payload.analytics)
            }
            if(response.payload?.next || response.payload?.previous) {
                const data = {
                    next: response.payload?.next,
                    prev: response.payload?.previous,
                }
                setpaginationData(data)
            }
        })
        .finally(() => setLoading(false))
    }

    const selectPaycode = (item) => {
        setPaycode(item)
        setModalShow(true)
        // setPayload((prevState) =>  ({...prevState, paycode_id: item.id}))
    }

    const handleActions = (item, option) => {
        setLoading(true)
        let request_payload = {
            action: option,
            reference: item.reference,
            paycode: item.token
        }
        dispatch(lockUnlockPaycode(request_payload)) 
        .then((response) => {
            if(response.payload?.success === true) {
                loadPaycodes()
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message || 'Successful'
                })
                .then(() => setPaycodeModal(false))
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message || 'Something went wrong'
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const loadAudits = (item) => {
        setLoading(true)
        let request_payload = {
            reference: item.reference,
            paycode: item.token
        }
        dispatch(getAudits(request_payload)) 
        .then((response) => {
            if(response.payload?.data) {
                setAudits(response.payload?.data)
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message || 'Something went wrong'
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const managePaycode = (item, option) => {
        Swal.fire({
            icon: 'warning',
            text: `This paycode will be ${option}ed. Do you wish to continue?`,
            showConfirmButton: true,
            confirmButtonText: 'Yes, Proceed',
            showCancelButton: true,
            cancelButtonText: 'No, Cancel'
        })
        .then((result) => {
            if(result.isConfirmed) {
                handleActions(item, option)
            }
        })
    }

    const handleSearch = () => {
        loadPaycodes()
    }

    const handlePagination = (data) => {
        setPayload({...payload, page: data})
    }

    const submitVoucherChargeUpdate = () => {
        if(!payload.charge_amount)  { return }
        setLoading(true)
        dispatch(loyaltyPaycodeCharge(payload))
        .then((response) => {
            if(response.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message
                })
                .then(() => setModalShow(false))
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }
    

    return (
        <Layout title="Loyalty Paycodes">
            <Container className="mt-4 px-4 mb-4">
                <div className="mt-4">
                    {
                        loading ? (
                            <div className="page-loader">
                                <Spinner size="lg" variant="primary"/>
                            </div>
                        ) : null
                    }
                    <Card className="mb-3 border-0 shadow-sm">
                        <Card.Body>
                            <div className="d-flex justify-content-between">
                                <h5>Overview</h5>
                                <div className="d-flex justify-content-end">
                                    <p>
                                        <select className="form-control">
                                            {
                                                months.length ? (
                                                    months.map(month => (
                                                        <option value={month.toLowerCase()} key={month}>{month}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                    </p>
                                </div>
                            </div>
                            <div className="mb-3 mt-3 analytic-row">
                                <div className="shadow-sm card bg--primary">
                                    <div className="d-flex align-items-center gap-2 px-2 py-4">
                                        <div className="icon ">
                                        </div>
                                        <div>
                                            <h3 className="m-0 text-primary font-bold">{formatCurrency(analytics?.total_issued_paycodes).formatted}</h3>
                                            <p className="text-muted text-sm m-0">Total Vouchers</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="shadow-sm card">
                                    <div className="d-flex align-items-center gap-2 px-2 py-4">
                                        <div className="icon bg-light">
                                        </div>
                                        <div>
                                            <h3 className="m-0 text-success font-bold">{formatCurrency(analytics?.total_paycode_value).with_currency}</h3>
                                            <p className="text-muted text-sm m-0">Total Paycode Value</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="shadow-sm card">
                                    <div className="d-flex align-items-center gap-2 px-2 py-4">
                                        <div className="icon">
                                        </div>
                                        <div>
                                            <h3 className="m-0 text-dark font-bold">{formatCurrency(analytics?.total_overall_top_up).with_currency}</h3>
                                            <p className="text-muted text-sm m-0">Overall Topup</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="shadow-sm card">
                                    <div className="d-flex align-items-center gap-2 px-2 py-4">
                                        <div className="icon">
                                        </div>
                                        <div>
                                            <h3 className="m-0 text-danger font-bold">{formatCurrency(analytics?.total_used_amount).with_currency}</h3>
                                            <p className="text-muted text-sm m-0">Total Used Amount</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="shadow-sm card">
                                    <div className="d-flex align-items-center gap-2 px-2 py-4">
                                        <div className="icon">
                                        </div>
                                        <div>
                                            <h3 className="m-0 text-warning font-bold">{formatCurrency(analytics?.total_expired_count).formatted}</h3>
                                            <p className="text-muted text-sm m-0">Total Expired</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="shadow-sm card">
                                    <div className="d-flex align-items-center gap-2 px-2 py-4">
                                        <div className="icon">
                                        </div>
                                        <div>
                                            <h3 className="m-0 text-secondary font-bold">{formatCurrency(analytics?.total_expired_amount).with_currency}</h3>
                                            <p className="text-muted text-sm m-0">Total Expired Amount</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>

                    <RecordFilter
                        handleInput={handleInput}
                        handleSearchSubmit={handleSearch}
                    />

                    <Card className="border-0 shadow-sm">
                        <Card.Body className="table-responsive-lg">
                            <Table size="sm" className="table-color">
                                <thead>
                                    <tr>
                                        <th>Customer Name</th>
                                        <th>Customer Email</th>
                                        <th>Customer Phone</th>
                                        <th>Amount</th>
                                        <th>Balance</th>
                                        <th>Date Created</th>
                                        <th>Token</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        paycodes?.length ? (
                                            paycodes.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {item.customer_name}
                                                        <button
                                                            style={{border: "none", display: "block", backgroundColor: "transparent"}}
                                                            className="text-primary p-0"
                                                            onClick={ () => selectPaycode(item) }
                                                        >
                                                            Voucher History
                                                        </button>
                                                    </td>
                                                    <td>{item.customer_email}</td>
                                                    <td>{item.customer_phone}</td>
                                                    <td>
                                                        {formatCurrency(item.top_up_amount).with_currency}
                                                    </td>
                                                    <td>
                                                        {formatCurrency(item.balance).with_currency}
                                                    </td>
                                                    <td>
                                                        {dateFormatter(item.created_at)}
                                                    </td>
                                                    <td>
                                                        {item.code}
                                                    </td>
                                                    <td>
                                                        <p className="d-flex m-0 align-items-center justify-content-between">
                                                            <Badge 
                                                                className={!item.expired ? 'badge-active' : 
                                                                (item.used_amount > 0 === 'used' ? 'badge-used' : 
                                                                (!item.expired ? 'badge-failed' : 'badge-pending')
                                                                )}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                    <circle cx="5" cy="5" r="5" fill={
                                                                        !item.expired ? "#008000" : 
                                                                        (item.used_amount > 0 ? "#828282" : "#FAB941")
                                                                    }
                                                                    />
                                                                </svg>
                                                                <span className="ps-1">
                                                                    {!item.expired ? 'Active' : (item.used_amount > 0 ? 'Used' : 'Expired')}
                                                                </span>
                                                            </Badge> 
                                                        </p>
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                        :
                                        (
                                            <tr>
                                                <td colSpan={5} className="text-center text-muted">No data found</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    {
                        paycodes?.length ? (
                            <div className="table-responsive mt-2">
                                <Paginator
                                    next={paginationData.next}
                                    prev={paginationData.prev}
                                    setCurrentPage={ handlePagination }
                                />
                            </div>
                        ) : null
                    }
                </div>

                <Modal
                    size="lg"
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    backdrop='static'
                    className="border-0"
                >
                    <Modal.Header closeButton className="py-2 custom-card-header">
                        PayCode Details
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <Table size="sm" className="table-color">
                                    <thead>
                                        <tr>
                                            <th>Customer</th>
                                            <th>Amount</th>
                                            <th>Used</th>
                                            <th>Balance</th>
                                            <th>Phone Number</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{paycode.customer_name}</td>
                                            <td>
                                                {formatCurrency(paycode.top_up_amount).with_currency}
                                            </td>
                                            <td>
                                                {formatCurrency(paycode.used_amount).with_currency}
                                            </td>
                                            <td>
                                                {formatCurrency(paycode.balance).with_currency}
                                            </td>
                                            <td>
                                                {paycode.customer_phone}
                                            </td>
                                            <td>{paycode.expires_at != '' ? dateFormatter(paycode.expires_at) : '---'}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <div className="mt-3">
                                    {
                                        setCharge && (
                                            <div className="mt-3 col-md-4 col-sm-12">
                                                <label htmlFor="amount">Amount</label>
                                                <input type="number" name="charge_amount" min={0} className="form-control" placeholder="0" onChange={handleInput} required/>
                                            </div>
                                        )
                                    }
                                    <div className="mt-3">
                                        <button 
                                            type="button" 
                                            className="btn-plain btn bg-primary rounded text-white"
                                            onClick={() => setCharges(!setCharge)}
                                        >
                                           {!setCharge ? 'Set Charge Amount' : 'Cancel'} 
                                        </button>
                                        {
                                            setCharge && (
                                                <button 
                                                    type="button" 
                                                    className="btn-plain btn bg-success rounded text-white ms-2"
                                                    onClick={ submitVoucherChargeUpdate }
                                                >
                                                    Submit <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                                </button>
                                            )
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                {/* <Modal
                    size="lg"
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    backdrop='static'
                    className="border-0"
                >
                    <Modal.Header closeButton className="py-2 custom-card-header">
                        PayCode Details
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <Table size="sm" className="table-color">
                                    <thead>
                                        <tr>
                                            <th>Transaction Type</th>
                                            <th>Amount</th>
                                            <th>Balance Before</th>
                                            <th>Balance After</th>
                                            <th>Fee</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            paycodeHistory?.length ? (
                                                paycodeHistory.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.transaction_type}</td>
                                                        <td>
                                                            {formatCurrency(item.amount).with_currency}
                                                        </td>
                                                        <td>
                                                            {formatCurrency(item.amount_before).with_currency}
                                                        </td>
                                                        <td>
                                                            {formatCurrency(item.amount_after).with_currency}
                                                        </td>
                                                        <td>
                                                            {formatCurrency(item.amount_after).with_currency}
                                                        </td>
                                                        <td>{item.status}</td>
                                                    </tr>
                                                ))
                                            )
                                            :
                                            (
                                                <tr>
                                                    <td colSpan={5} className="text-center text-muted">No data found</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal> */}

                {/* <Modal
                    size="lg"
                    show={audit_modal}
                    onHide={() => setAuditModal(false)}
                    backdrop='static'
                    className="border-0"
                >
                    <Modal.Header closeButton className="py-2 custom-card-header">
                        PayCode Aduit Trail
                    </Modal.Header>
                    <Modal.Body>
                        <Table className="table" size="sm">
                            <thead>
                                <tr>
                                    <th>Reference</th>
                                    <th>Sender</th>
                                    <th>Amount</th>
                                    <th>Token</th>
                                    <th>Used By</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    audits.length ? (
                                        audits.map(auditItem => (
                                            <tr>
                                                <td>{auditItem.reference}</td>
                                                <td>{auditItem.sender}</td>
                                                <td>{formatCurrency(auditItem.amount).with_currency}</td>
                                                <td>{auditItem.token}</td>
                                                <td>{auditItem.used_by}</td>
                                                <td>{auditItem.date}</td>
                                                <td>{auditItem.status}</td>
                                            </tr>
                                        ))
                                    ) : 
                                    <tr className="border-0">
                                        <td colSpan={7} className="text-center">No data available</td>
                                    </tr>
                                }
                                <tr></tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                </Modal> */}
            </Container>
        </Layout>
    );
}

export default LoyaltyPaycode;