import { Row, Col, Container, Card, Form, Button, Table, Dropdown, Spinner } from "react-bootstrap";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../../components/table/Paginator";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getWithrawalLimitList, manageWithdrawalLimit } from "../../redux/slices/account.service";
import dateFormatter from "../../utils/dateFormatter";
import Swal from "sweetalert2";
import { withdrawalLimitRequest } from "../../redux/slices/users";
import formatCurrency from "../../utils/currencyFormmter";

const TransferLimit = () => {


    const dispatch = useDispatch()

    const [results, setResults] = useState([])
    const [analytics, setAnalytics] = useState({})
    const [tab, setTab] = useState('requests')
    const [paginationData, setpaginationData] = useState({next: '', prev: ''})
    const [payload, setPayload] = useState({amount: null})
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)

    
    const [filter_payload, setFilterPayload] = useState({
        page: 1,
        from_date: '',
        to_date: '',
        status: '',
        download: '',
    })

    useEffect(() => {
        loadWithdrawalLimits()
    }, [filter_payload])


    const loadWithdrawalLimits = () =>  {
        dispatch(getWithrawalLimitList(filter_payload))
        .then((response) => {
            if(response.payload.results) {
                setResults(response.payload.results)
            }
            if(response.payload?.analytics) {
                setAnalytics(response.payload.analytics)
            }
            if(response.payload?.next || response.payload?.previous) {
                const data = {
                    next: response.payload?.next,
                    prev: response.payload?.previous,
                }
                setpaginationData(data)
            }
        })
    }


    const handlePagination = (data) => {
        setFilterPayload({...filter_payload, page: data})
    }

    const handleManagement = (id, option) => {
        let status = option === 'approved' ? 'approve' : (option === 'rejected' ? 'reject' : '')
        Swal.fire({
            icon: 'warning',
            text: `Are you sure you want to ${status} this request?`,
            showCancelButton: true,
            confirmButtonText: 'Yes, Proceed',
            cancelButtonText: 'No, Cancel'
        })
        .then((result) => {
            if (!result.isConfirmed) return;
            manageLimits(id, option)
        })
    }

    const manageLimits = (id, option) => {        
        dispatch(manageWithdrawalLimit({id, status: option}))
        .then(res => {
            if(res.payload?.success === true){
                loadWithdrawalLimits()
                Swal.fire({
                    icon: "success",
                    text: res.payload?.message
                })
            }
            else {
                Swal.fire({
                    icon: "error",
                    text: res.payload.message ??  "Something went wrong!"
                })
            }
        })
    }

    const handleInput = (event) => {
        const { name, value } = event.target
        if(name === 'amount' || name === 'fund_amount') {
            const newValue = value.replace(/[^\d.]/g, '')
            setPayload((prevState)=> ({...prevState,[name]:newValue}))
        }
        else {
            setPayload((prevState)=> ({...prevState,[name]:value}))
        }
    }

    const handleValidation = (event) => {

        const regex = /^\d*\.?\d*$/;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    
        const {name, value} = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if(name === 'email' && !pattern.test(value)) {
            setErrors((prevState) => ({ ...prevState, [name]: 'Please enter a valid email address'}))
        }
        else if ((name ==='amount' || name === 'fund_amount') && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if (name ==='amount' && parseFloat(value.replace(/[^\d.]/g, '')) > 200000) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: 'You can not withdraw above maximum limit' }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }

    }

    const handleRangeSlide = (event) => {
        const value = event.target.value;
        const newValue = value.replace(/[^\d.]/g, '')
        setPayload((prevState)=> ({...prevState,'limit':newValue}))
    }

    const sendWithrawalLimitRequest = () => {
        if (!payload.limit) {return}

        setLoading(true)
        dispatch(withdrawalLimitRequest({requested_limit: payload.limit}))
        .then((response) => {
            console.log(response)
            if(response.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message
                })
                .then(() => {
                    // setLimit(false)
                })
            }
            else if(response.payload?.success === 'False') {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }
 
    return (
        <Layout title="Transfer limits">
            <Container className="mt-4 px-4 px-lg-4 px-sm-2 px-md-2 mb-4">
            <Col className="d-flex">
                    <button 
                        type="button" 
                        className={`btn-plain rounded p-2 me-2  ${tab === "limits" ? "bg-white text-primary" : "text-muted"}`}
                        onClick={ () => setTab("limits") }
                    >
                        Transfer Limits
                    </button>                  
                    <button 
                        type="button" 
                        className={`btn-plain rounded p-2 ms-2  ${tab === "requests" ? "bg-white text-primary" : "text-muted"}`}
                        onClick={ () => setTab("requests") }
                    >
                        Transfer Limit Requests
                    </button>                  
                </Col>
                <div className="mt-4">
                    <Card className="border-0 shadow-sm mt-5">
                        <Card.Body className="table-responsive-sm">
                            { tab === 'limits' ? (
                                <div className="p-3">
                                    <Form>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className="text-sm">Current Transfer Limit</p>
                                            <p className="text-sm text-primary">56,000 </p>

                                        </div>
                                        <Row>
                                            <Form.Group as={Col} md={12} className="mb-3">
                                                <Form.Label className="mb-0">Request New limit</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="limit"
                                                    placeholder="₦56,000.00"
                                                    required
                                                    onChange={handleInput}
                                                    onKeyUp={handleValidation}
                                                    value={ parseFloat(payload.limit) > 0 ? formatCurrency(payload.limit).formatted : ''}
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Range 
                                                    className="custom-range"
                                                    // defaultValue={ business.withdrawal_limit }
                                                    onChange={ handleRangeSlide }
                                                    min={20000} 
                                                    max={10000000} 
                                                />
                                            </Form.Group>

                                            <Form.Group 
                                                as={Col} 
                                                md={12} 
                                                className="mt-4 d-grid"
                                            >
                                                <Button 
                                                    variant="primary" 
                                                    size="lg" 
                                                    onClick={sendWithrawalLimitRequest}
                                                >
                                                    Set  New Limit <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                                </Button>
                                            </Form.Group>
                                        </Row>
                                    </Form>
                                </div>
                                ) : (
                                <Table size="sm" className="table-color table-stripedd">
                                    <thead>
                                        <tr>
                                            <th>Wallx ID</th>
                                            <th>Business Name</th>
                                            <th>Old Withdrawal Limit</th>
                                            <th>Withdrawal Limit Requested</th>
                                            <th>Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            results?.length ? (
                                                results?.map((item, index) => (
                                                    <tr key={index+tab}>
                                                        <td>#{item.merchant}</td>
                                                        <td>
                                                            <div className="m-0 d-flex gap-2 align-items-center">
                                                                <strong>
                                                                    <p className="m-0">{`${item.merchant_name}`} </p>  
                                                                </strong>
                                                            </div>
                                                        </td>
                                                        <td> {formatCurrency(item.requested_limit).with_currency}  </td>
                                                        <td> {formatCurrency(item.requested_limit).with_currency}  </td>
                                                        <td>{dateFormatter(item.date)}</td>
                                                        <td className="d-flex">
                                                            <button 
                                                                type="button" 
                                                                className={`"btn-plain border-0 px-3 rounded " ${item.status === "approved" ? "bg-success text-white" : item.status === "rejected" ? "bg-danger text-white" : "bg-primary text-white"}`}
                                                            > 
                                                                {item.status}
                                                            </button>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="light" className="border-0 bg-transparent" size="sm" id="dropdown-basic">
                                                                    <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                    {/* <Dropdown.Item 
                                                                        eventKey={1} 
                                                                        className="text-primary d-flex align-items-center gap-1" 
                                                                        // onClick={() => handleManagement(item.email, 'view')}
                                                                    >
                                                                    <i className="lni lni-eye" style={{fontSize: "20px"}}></i>  View Details
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider /> */}
                                                                    <Dropdown.Item 
                                                                        eventKey={1} 
                                                                        className="text-success d-flex align-items-center gap-1" 
                                                                        onClick={() => handleManagement(item.id, 'approved')}
                                                                    >
                                                                    <FontAwesomeIcon icon={"check"} />  Approve Request
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item 
                                                                        eventKey={1} 
                                                                        className="text-danger d-flex align-items-center gap-1" 
                                                                        onClick={() => handleManagement(item.id, 'rejected')}
                                                                    >
                                                                    <FontAwesomeIcon icon={"cancel"} />  Decline Request
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                ))
                                            )
                                            : 
                                            (
                                                <tr>
                                                    <td colSpan={5} className="text-center text-muted">No data found</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>                                           
                                </Table>
                                )
                            }
                        </Card.Body>
                    </Card>
                    {
                        results?.length && tab === 'requests' ? (
                            <div className="table-responsive mt-2">
                                <Paginator
                                    next={paginationData.next} 
                                    prev={paginationData.prev}
                                    setCurrentPage={ handlePagination }
                                />
                            </div>
                        ) : null
                    }
                </div>
            </Container>
        </Layout>
    );
}

export default TransferLimit;