import { Row, Col, Container, Card, Button, Table, Badge, Dropdown, Form, Spinner } from "react-bootstrap";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../../components/table/Paginator";
import { useEffect, useState } from "react";
import avatar from '../../assets/images/avatar.svg';
import DrewsModal from "../../components/modal/DrewsModal";
import { useDispatch, useSelector } from "react-redux";
import { createNewTicket, getAllTickets, manageTicket } from "../../redux/slices/support.slice";
import Swal from "sweetalert2";
import { clearMessage } from "../../redux/slices/message";
import { useNavigate } from "react-router-dom";
import dateFormatter from "../../utils/dateFormatter";


const Tickets = () => {

    const dispatch = useDispatch()

    const { message } = useSelector((state) => state.message)

    const navigate = useNavigate()


    const [modalShow, setModalShow] = useState(false)
    const [showForm, setForm] = useState(false)
    const [tickets, setTickets] = useState([])
    const [transaction, setTransaction] = useState({})
    const [loading, setLoading] = useState(false)
    const [payload, setPayload] = useState({})
    const [errors, setErrors] = useState({})
    const [attachment, setAttachment] = useState(null)

    useEffect(() => {
        loadTickets()
    }, [0])


    const handleInput = (event) => {
        const { name, value } = event.target;
        if(name === 'save_beneficiary') {
            let val = event.target.checked ? 'yes' : 'no'
            setPayload((prevState) => ({...prevState, [name]: val}))
        }
        else {
            setPayload((prevState) => ({...prevState, [name]: value}))
        }
    }

    const handleValidation = (event) => {

        const { name, value } = event.target

        const regex = /^\d*\.?\d*$/;

        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if (name ==='amount' && !regex.test(value)) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }
    }


    const selectTransaction = (id) => {
        // setModalShow(true)
        // setTransaction(tickets.filter(item => item.id === id)[0])
        navigate(`/tickets/${id}`)
    }

    const loadTickets = () => {
        dispatch(getAllTickets())
        .then((res) => {
            console.log(res)
            if(res.payload?.results) {
                setTickets(res.payload.results)
            }
        })
    }

    const submitTicket = () => {
        let formdata = new FormData()
        formdata.append('attachment', attachment)
        formdata.append('desk', payload.department)
        formdata.append('message', payload.message)
        formdata.append('subject', payload.subject)
        dispatch(createNewTicket(formdata))
        .then((response) => {
            if(response.payload?.success) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message
                }).then(() => setForm(false))
            }
            else if(response.payload?.message) {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
            loadTickets()
        })
    }

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            setAttachment(reader.result);
        };
    }

    const resolveTicket = (id) => {
        dispatch(manageTicket(id))
        .then((res) => {
            if(res.payload?.success) {
                Swal.fire({
                    icon: "success",
                    text: res.payload?.message,
                })
            }
            else{
                Swal.fire({
                    icon: "error",
                    text: res.payload?.message,
                });
            }
            loadTickets()
        })
    }

    return (
        <Layout title="Support Tickets">
            <Container className="mt-4 px-4 mb-4">

                <div className="mt-4">
                    <div className="mb-3 mt-3 analytic-row">
                        <div className="shadow-sm border-0 card bg--primary">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon ">
                                </div>
                                <div>
                                    <h3 className="m-0 text-primary font-bold">0</h3>
                                    <p className="text-muted text-sm m-0">Total Tickets</p>
                                </div>
                            </div>
                        </div>
                        <div className="shadow-sm border-0 card">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon bg-light">
                                </div>
                                <div>
                                    <h3 className="m-0 text-success font-bold">0</h3>
                                    <p className="text-muted text-sm m-0">Answered</p>
                                </div>
                            </div>
                        </div>
                        <div className="shadow-sm border-0 card">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon">
                                </div>
                                <div>
                                    <h3 className="m-0 text-warning font-bold">0</h3>
                                    <p className="text-muted text-sm m-0">Closed Tickets</p>
                                </div>
                            </div>
                        </div>
                        <div className="shadow-sm border-0 card">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon">
                                </div>
                                <div>
                                    <h3 className="m-0 text-secondary font-bold">0</h3>
                                    <p className="text-muted text-sm m-0">Pending Tickets</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Card className="border-0 shadow-sm mb-3">
                        <Card.Body className="table-responsive-lg table-responsive">
                            <Table size="sm" className="table-color">
                                <thead>
                                    <tr>
                                        <th>Ticket ID</th>
                                        <th>Subject</th>
                                        <th>Handled by</th>
                                        <th>Date Created</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tickets?.length ? (
                                            tickets?.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        #{item.ticketID}
                                                    </td>
                                                    <td>
                                                        <div className="m-0 d-flex gap-2 align-items-center">
                                                            <strong className="text-dark">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                    <path d="M10 0L20 10L10 20L0 10L10 0Z" fill="#EB5757"/>
                                                                </svg>
                                                                Complaint,
                                                            </strong>
                                                            <span>
                                                                {item.subject}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {item.desk || 'Nil'}
                                                    </td>
                                                    <td>
                                                        {dateFormatter(item.date)}
                                                    </td>
                                                    <td>
                                                        <div className="d-flex m-0 align-items-center justify-content-between">
                                                            <Badge 
                                                                className={item?.status.toLowerCase() === 'success' ? 'badge-active' : 
                                                                (item?.status.toLowerCase() === 'failed' ? 'badge-failed' : 
                                                                (item?.status.toLowerCase() === 'resolved' ? 'badge-used' : 'badge-pending'))}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                    <circle cx="5" cy="5" r="5" fill={
                                                                        item.status.toLowerCase() === 'success' ? "#008000" : 
                                                                        (item.status.toLowerCase() === 'failed' ? "#EB5757" : 
                                                                        (item.status.toLowerCase() === 'resolved' ? "#828282" : "#FAB941"))
                                                                    }
                                                                    />
                                                                </svg>
                                                                <span className="ps-1">
                                                                    {item.status}
                                                                </span>
                                                            </Badge> 
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="light" className="border-0 bg-white" size="sm" id="dropdown-basic">
                                                                    <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                </Dropdown.Toggle>
    
                                                                <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                    <Dropdown.Item 
                                                                        eventKey={1} 
                                                                        className="text-primary d-flex align-items-center gap-1" 
                                                                        onClick={() => selectTransaction(item.ticketID)}
                                                                    >
                                                                    <i className="lni lni-eye" style={{fontSize: "20px"}}></i>  View Ticket
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item 
                                                                        eventKey={2} 
                                                                        className="d-flex align-items-center gap-1"
                                                                        onClick={ () => resolveTicket(item.ticketID) }
                                                                    >
                                                                        <FontAwesomeIcon icon="folder-open" /> Mark as resolved
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                        : (
                                            <tr>
                                                <td colSpan={5} className="text-center text-muted mt-4">No data available</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    {
                        tickets?.length ? (
                            <Paginator/>
                        ) : null
                    }
                </div>
                <DrewsModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    dismissable={true}
                    title={transaction.reference}
                    badge_text={transaction.type}
                >
                    <Row>
                        <Col md={6}>
                            <div className="modal-profile">
                                <ul>
                                    <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="text-muted">Transaction ID</small>
                                                {transaction.reference} 
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="text-muted">Beneficiary Name</small>
                                                {transaction.name} 
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="text-muted">Beneficiary Account</small>
                                                {transaction.balance}
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="text-muted">Status</small>
                                                <span 
                                                className={transaction.status === 'success' ? 'text-success' : 
                                                (transaction.status === 'failed' ? 'text-danger' : 
                                                (transaction.status === 'used' ? 'text-dark' : 'text-warning'))}
                                                >
                                                    {transaction.status}
                                                </span> 
                                            </p>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </Col>
                        <Col md={6} className="modal-profile">
                            <ul>
                                <li>
                                    <div>
                                        <p className="pt-2">
                                            <small className="text-muted">Amount</small>
                                            {transaction.amount}
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <p className="pt-2">
                                            <small className="text-muted">Transaction Date</small>
                                            {transaction.date}
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </DrewsModal>
                <DrewsModal
                    show={showForm}
                    onHide={() => setForm(false)}
                    size="md"
                    dismissable={true}
                    title="Create a Ticket"
                >
                    <Form>
                        <Row>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label>Ticket Desk</Form.Label>
                                <Form.Select 
                                    name="department"
                                    required
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                >
                                    <option value="">---Select an option---</option>
                                    <option value="help desk">Helpdesk</option>
                                    <option value="sales">Sales</option>
                                    <option value="support">Support</option>
                                    <option value="IT">IT</option>
                                </Form.Select>
                                {
                                    errors['department'] ? 
                                    <Form.Text className="text-danger">{errors['department']}</Form.Text> : null
                                }
                            </Form.Group>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label>Subject</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="Subject"
                                    name="subject"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['subject'] ? 
                                    <Form.Text className="text-danger">{errors['subject']}</Form.Text> : null
                                }
                            </Form.Group>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label>Message</Form.Label>
                                <textarea 
                                    name="message" 
                                    className="form-control" 
                                    placeholder="Message"
                                    required
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                ></textarea>
                                {
                                    errors['message'] ? 
                                    <Form.Text className="text-danger">{errors['message']}</Form.Text> : null
                                }
                            </Form.Group>
                            <Form.Group as={Col} md={12} controlId="validationCustom011" className="mb-3">
                                <Form.Label className="mb-0 text-sm">Attachment</Form.Label>
                                <label htmlFor="attachment" className="custom-file-select form-control form-control-lg p-3">
                                    <FontAwesomeIcon icon="fa fa-arrow-up-from-bracket" /> Upload Attachment
                                    <input
                                        type="file"
                                        name="attachment"
                                        accept="image/*"
                                        size="lg"
                                        id="attachment"
                                        onChange={handleFileSelect}
                                    />
                                </label>
                            </Form.Group>
                            <Form.Group 
                                as={Col} 
                                md={12} 
                                className="mt-4 d-grid"
                            >
                                <Button 
                                    variant="primary" 
                                    size="lg"
                                    onClick={submitTicket}
                                    disabled={Object.keys(errors).length > 0}
                                >
                                    Send Ticket <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </DrewsModal>
            </Container>
        </Layout>
    );
}

export default Tickets;