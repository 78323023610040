import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Pagination } from "react-bootstrap";


const Paginator = (props) => {

    const setCurrentPage = props.setCurrentPage

    const [current_page, setCurentPage] = useState(1)


    function extractPageNumber(link) {
        // Parse the URL to get the search parameters
        const url = new URL(link);
        
        // Get the value of the 'page' query parameter
        const pageNumber = url.searchParams.get('page');
        setCurentPage(pageNumber)
        setCurrentPage(pageNumber)
        return pageNumber;
    }

    return (
        <div className="d-flex">
            <div className="page-item-counter">
                {/* <span>1-7 of 400</span> */}
            </div>
            <Pagination>
                <div className="page-item-prefix">
                    <button 
                        className="px-3 btn-plain" 
                        type="button" 
                        disabled={!props.prev}  onClick={() => extractPageNumber(props.prev)}
                    >
                        <FontAwesomeIcon icon="arrow-left"/> 
                        <span className="ms-2">Previous</span>
                    </button>
                </div>
                {/* <Pagination.First onClick={()=> props.handlePageChange("first")} />
                <Pagination.Prev onClick={()=> props.handlePageChange("prev")}/>
                {props.pages.map((page, index)=>(
                    <Pagination.Item key={index} active={page.active}>{page.value}</Pagination.Item>
                ))}
                <Pagination.Next onClick={()=> props.handlePageChange("next")} />
                <Pagination.Last onClick={()=> props.handlePageChange("last")} /> */}
                <Pagination.Item active>{current_page}</Pagination.Item>
                {/* <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>
                <Pagination.Item>{4}</Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item>{10}</Pagination.Item> */}
                <div className="page-item-prefix">
                    <button 
                        type="button" 
                        className="px-3 btn-plain" 
                        disabled={!props.next}
                        onClick={() => extractPageNumber(props.next)}
                    >
                       <span className="me-2">Next</span>
                        <FontAwesomeIcon icon="arrow-right"/>
                    </button> 
                </div>
            </Pagination>
        </div>
    );
}

export default Paginator;