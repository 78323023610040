import { Badge, Modal} from "react-bootstrap";


const DrewsModal = (props) => {
    const { children } = props
    return (
        <Modal
            size={props.size || 'md'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.show}
            onHide={props.onHide || null}
            backdrop='static'
            className="border-0"
        >
            { props.title ? (
                <Modal.Header 
                    closeButton={props.dismissable || false}
                    className="py-2"
                >
                    <h5>{props.title}</h5> 
                    { props.badge_text ? 
                        (
                            <Badge className="badge badge-active m-auto p-2">{props.badge_text}</Badge>
                    ) : null }
                </Modal.Header>
            ) : null }
            
            <Modal.Body className="p-4">
                {children}
            </Modal.Body>
        </Modal>
    );
}

export default DrewsModal;