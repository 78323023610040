import Navigation from "./Navigation";
import AppSidebar from "./Sidebar";

const Layout = (props) => {
    const { children } = props;

    return (
        <>
            <div className="main-layout">
                <AppSidebar />
                <div className="dashboard">
                    <Navigation title={props.title || 'WallX Business Admin'}/>
                    {children}
                </div>
            </div>
        </>
    );
}

export default Layout;