import ServiceHelper from "./service.helper";

export default class P2PService {

    static async search(id) {
        return await ServiceHelper.handleGetRequest(`/p2p_seacrh/?merchant_id=${id}`);
    }

    static async getMerchant(id) {
        return await ServiceHelper.handleGetRequest(`/merchants/?merchant_id=${id}`);
    }

    static transfer(payload) {
        return ServiceHelper.handlePostRequest("/p2p_transfer/", payload);
    }

    static addBeneficiary(id) {
        return ServiceHelper.handlePostRequest("/beneficiaries/", {action: 'add', merchant: id});
    }

    static removeBeneficiary(id) {
        return ServiceHelper.handlePostRequest("/beneficiaries/", {action: 'remove', merchant: id});
    }

    static getBeneficiaries() {
        return ServiceHelper.handleGetRequest('/beneficiaries/')
    }
}