import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import BillsPaymentService from '../../services/billspayment.service';

// Get list of service providers
export const getServiceProviders = createAsyncThunk(
  "/bills/providers",
  async(service, thunkAPI) => {
    try {
      const response = await BillsPaymentService.getProviders(service);
      console.log('service providers', response)
      return response;
    } catch (error) {
      console.log(error)
      return error
    }
  }
)

// Get list of mobile data bundle of a specific network provider
export const mobileBundles = createAsyncThunk(
  "/bills/data_bundle/",
  async(network, thunkAPI) => {
    try {
      const response = await BillsPaymentService.getDataBundles(network);
      return response;
    } catch (error) {
      console.log(error)
      return error
    }
  }
)

// Get list of bouques of a specific tv provider
export const tvBouque = createAsyncThunk(
  "/bills/cable_tv",
  async(provider, thunkAPI) => {
    try {
      const response = await BillsPaymentService.getBouque(provider);
      return response;
    } catch (error) {
      console.log(error)
      return error
    }
  }
)

// Get list of addons of a specific tv provider
export const tvAddOns = createAsyncThunk(
    "/bills/cable_tv",
    async(payload, thunkAPI) => {
      try {
        const response = await BillsPaymentService.getAddOns(payload);
        return response;
      } catch (error) {
        console.log(error)
        return error
      }
    }
)

// Validate meter number and tv decoders
export const customerDeviceValidation = createAsyncThunk(
  "/bills/validation",
  async(payload, thunkAPI) => {
    try {
      console.log(payload)
      const response = await BillsPaymentService.accountValidation(payload);
      return response;
    } catch (error) {
      console.log(error)
      return error
    }
  }
)

// Make purchase  for the bill payment service
export const payBills = createAsyncThunk(
  "/bills/purchase",
  async(payload, thunkAPI) => {
    try {
      const response = await BillsPaymentService.makePurchase(payload);
      return response;
    } catch (error) {
      console.log(error)
      return error
    }
  }
)



const billsSlice = createSlice({
  name: "bills",
  initialState: {
    providers: null,
    products: null,
  },
  reducers: {
    setProviders(state, data) {
      state.providers = data
    },
    setProducts(state, data) {
      state.products = data
    },
  },
})

const { reducer } = billsSlice
export default reducer;