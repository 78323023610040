import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Dropdown } from "react-bootstrap";
import dateFormatter from "../utils/dateFormatter";

const BusinessRows = (props) => {
    return (
        <tbody>
            {
                props.data?.length ? (
                    props.data?.map((item, index) => (
                        <tr key={index} className="d-flex align-items-center">
                            <td>
                                <div className="m-0 d-flex gap-2 align-items-center">
                                    <div className="profile-avatar-sm">
                                        <img src={item.profile_photo} alt={item.name} width={20} />
                                    </div>
                                    <strong className="text-dark">
                                       {`${item.first_name+' '+item.last_name.charAt(0)}`}.
                                    </strong>
                                </div>
                            </td>
                            {
                                props.show_email ? 
                                    <td>
                                        {item.email || 'Nil'}
                                    </td>
                                : null
                            }
                            {
                                props.show_type ? 
                                    <td>
                                        {item.email || 'Nil'}
                                    </td>
                                : null
                            }
                            {
                                props.show_date ? 
                                    <td>
                                        {dateFormatter(item.date_joined)}
                                    </td>
                                : null
                            }
                            {
                                props.show_status ?  (
                                    <>
                                        <td>
                                            <div className="d-flex m-0 align-items-center justify-content-between">
                                            <Badge 
                                                className={item?.status.toLowerCase() === 'success' ? 'badge-active' : 
                                                (item?.status.toLowerCase() === 'failed' ? 'badge-failed' : 
                                                (item?.status.toLowerCase() === 'resolved' ? 'badge-used' : 'badge-pending'))}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                    <circle cx="5" cy="5" r="5" fill={
                                                        item.status.toLowerCase() === 'success' ? "#008000" : 
                                                        (item.status.toLowerCase() === 'failed' ? "#EB5757" : 
                                                        (item.status.toLowerCase() === 'resolved' ? "#828282" : "#FAB941"))
                                                    }
                                                    />
                                                </svg>
                                                <span className="ps-1">
                                                    {item.status}
                                                </span>
                                            </Badge> 
                                            <Dropdown>
                                                <Dropdown.Toggle variant="light" className="border-0 bg-white" size="sm" id="dropdown-basic">
                                                    <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                    <Dropdown.Item 
                                                        eventKey={1} 
                                                        className="text-primary d-flex align-items-center gap-1" 
                                                        // onClick={() => selectTransaction(item.ticketID)}
                                                    >
                                                    <i className="lni lni-eye" style={{fontSize: "20px"}}></i>  View Details
                                                    </Dropdown.Item>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item 
                                                        eventKey={2} 
                                                        className="d-flex align-items-center gap-1"
                                                        // onClick={ () => resolveTicket(item.ticketID) }
                                                    >
                                                        <FontAwesomeIcon icon="folder-open" /> Transaction History
                                                    </Dropdown.Item>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item 
                                                        eventKey={2} 
                                                        className="d-flex align-items-center gap-1"
                                                        // onClick={ () => resolveTicket(item.ticketID) }
                                                    >
                                                        <FontAwesomeIcon icon="cancel" /> Deactivate
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            </div>
                                        </td>
                                    </>
                                ) : null
                            }
                            {
                                !props.show_status ? (
                                    <td>
                                        <Dropdown className="mini-dropdown ms-auto">
                                            <Dropdown.Toggle variant="light" className="border-0 bg-white" size="sm" id="dropdown-basic">
                                                <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "15px"}} className="text-muted"/>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                <Dropdown.Item 
                                                    eventKey={1} 
                                                    className="text-primary d-flex align-items-center gap-1" 
                                                    // onClick={() => selectTransaction(item.ticketID)}
                                                >
                                                <i className="lni lni-eye" style={{fontSize: "20px"}}></i>  View Details
                                                </Dropdown.Item>
                                                <Dropdown.Divider />
                                                <Dropdown.Item 
                                                    eventKey={2} 
                                                    className="d-flex align-items-center gap-1"
                                                    // onClick={ () => resolveTicket(item.ticketID) }
                                                >
                                                    <FontAwesomeIcon icon="folder-open" /> Transaction History
                                                </Dropdown.Item>
                                                <Dropdown.Divider />
                                                <Dropdown.Item 
                                                    eventKey={2} 
                                                    className="d-flex align-items-center gap-1"
                                                    // onClick={ () => resolveTicket(item.ticketID) }
                                                >
                                                    <FontAwesomeIcon icon="cancel" /> Deactivate
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                ) : null
                            }
                        </tr>
                    ))
                )
                : (
                    <tr>
                        <td colSpan={5} className="text-center text-muted mt-4">No data available</td>
                    </tr>
                )
            }
        </tbody>
    );
}

export default BusinessRows;