import { Card } from "react-bootstrap";

const DataCardSm = (props) => {
    return  (
        <div className="data-card">
            <Card className={`border-0x shadow-sdm ${props.className}`}>
                <Card.Body>
                    <div className={`d-flex justify-content-start align-items-center mb-1`}>
                        <div className="icon">
                            <i className="lni lni-notepad"></i>
                        </div>
                        <h6>{props.title}</h6>
                    </div>
                    <div className="mt-3">
                        <h5 className="m-0">{props.amount || '₦0'}</h5>
                        <div className="footer">
                            <p className="text-muted">{props.text}</p>
                            <p>
                                {/* <span>100</span> */}
                            </p>
                        </div>
                    </div>               
                </Card.Body>
            </Card>
        </div>
    );
}


export default DataCardSm;