import { Col, Container, Card, Spinner } from "react-bootstrap";
import Layout from "../components/Layout";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getEarnings } from "../redux/slices/account.service";
import pound from '../assets/images/pound.svg';
import pound2 from '../assets/images/pound3.svg';
import list from '../assets/images/list.svg';
import list1 from '../assets/images/list1.svg';
import list3 from '../assets/images/list3.svg';
import list4 from '../assets/images/list4.svg';

import {
    Chart as ChartJS,
    Title,
    LineElement,
    PointElement,
    Tooltip,
    Filler,
    LinearScale,
    CategoryScale
  } from 'chart.js';
import { Line } from "react-chartjs-2";
import formatCurrency from "../utils/currencyFormmter";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler
);

const Earning = () => {

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                display: false
            },
        },
        scales: {
            x: {
                grid: { display: false }
            },
            y: {
                grid: { display: true }
            },
        }
    };

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Amount',
                data: [],
                borderColor: 'rgb(68, 55, 144)',
                backgroundColor: 'rgb(68, 55, 144)',
                fill: true,
                tension: 0.4,
            },
            {
                label: 'Month',
                data: [],
                borderColor: 'rgb(100, 97, 255)',
                backgroundColor: 'rgb(100, 97, 255)',
                fill: false,
                tension: 0.4,
            },
        ],
    }

    const dispatch = useDispatch()

    const [results, setResults] = useState([])
    const [analytics, setAnalytics] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        loadEarnings()
    }, [0])

    const loadEarnings = () =>  {
        setLoading(true)
        dispatch(getEarnings({}))
        .then((response) => {
            if(response.payload.results) {
                setResults(response.payload.results)
            }
            if(response.payload?.analytics) {
                setAnalytics(response.payload.analytics)
            }
        })
        .finally(() => setLoading(false))
    }
 
    return (
        <Layout title="Earnings">
            <Container className="mt-4 px-4 mb-4">
                <div className="mt-4">
                    {
                        loading ? (
                            <div className="page-loader">
                                <Spinner size="lg" variant="primary"/>
                            </div>
                        ) : null
                    }
                    <Col md={12} className="mb-3 mt-3 analytic-row earnings">
                        <div className="shadow-sm border-0 card">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon ">
                                </div>
                                <div>
                                    <h3>{formatCurrency(analytics?.total_paycode_earnings).with_currency}</h3>
                                    <p>
                                        <img src={pound} alt="pound" />
                                        Overall Total Earning
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="shadow-sm border-0 card">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon bg-light">
                                </div>
                                <div>
                                    <h3>{formatCurrency(analytics?.total_withdrawal_earnings).with_currency}</h3>
                                    <p>
                                        <img src={pound2} alt="pound" />
                                        Total Gross Earning (withdrawal)
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="shadow-sm border-0 card">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon">
                                </div>
                                <div>
                                    <h3>{formatCurrency(analytics?.total_paycode_earnings).with_currency}</h3>
                                    <p>
                                        <img src={list4} alt="pound" />
                                        Total Gross Earning (Paycode)
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="shadow-sm border-0 card">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon">
                                </div>
                                <div>
                                    <h3>{formatCurrency(analytics?.total_paycode_earnings).with_currency}</h3>
                                    <p>
                                        <img src={list} alt="list" />
                                        Total Paycode Earning (Diaspora)
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="shadow-sm border-0 card">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon">
                                </div>
                                <div>
                                    <h3>{formatCurrency(analytics?.total_failed_transactions).with_currency}</h3>
                                    <p>
                                        <img src={list1} alt="list" />
                                        Total Bills Earnings (Vend)
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="shadow-sm border-0 card">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon">
                                </div>
                                <div>
                                    <h3>{formatCurrency(analytics?.total_failed_transactions).with_currency}</h3>
                                    <p>
                                        <img src={list3} alt="list" />
                                        Total Gross Earning (Subcription)
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Card className="border-0 shadow-sm">
                        <Card.Header className="bg-white border-0">VEND CHART</Card.Header>
                        <Card.Body>
                            <Line options={options} data={data} />
                        </Card.Body>
                    </Card>
                </div>
            </Container>
        </Layout>
    );
}

export default Earning;