import { Row, Col, Container, Card, Form, Button, Table, Dropdown, Modal, Spinner } from "react-bootstrap";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../../components/table/Paginator";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createAdmin, getAllAdmin, getBusinesses, manageBusinessStatus, removeAdmin, suspendAdmin } from "../../redux/slices/account.service";
import dateFormatter from "../../utils/dateFormatter";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const AdminManagement = () => {


    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [results, setResults] = useState([])
    const [analytics, setAnalytics] = useState({})
    const [tab, setTab] = useState('all')
    const [paginationData, setpaginationData] = useState({next: '', prev: ''})
    const [invite, setInvite] = useState(false)
    const [payload, setPayload] = useState({amount: null})
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)
    
    const [filter_payload, setFilterPayload] = useState({
        page: 1,
        from_date: '',
        to_date: '',
        status: '',
        download: '',
    })

    useEffect(() => {
        getAdminListing()
    }, [filter_payload])


    const getAdminListing = () =>  {
        dispatch(getAllAdmin(filter_payload))
        .then((response) => {
            console.log("_______", response)
            if(response.payload instanceof Array) {
                setResults(response.payload)
            }
            // if(response.payload?.analytics) {
            //     setAnalytics(response.payload.analytics)
            // }
            // if(response.payload?.next || response.payload?.previous) {
            //     const data = {
            //         next: response.payload?.next,
            //         prev: response.payload?.previous,
            //     }
            //     setpaginationData(data)
            // }
        })
    }


    const handlePagination = (data) => {
        setFilterPayload({...filter_payload, page: data})
    }

    const handleManagement = (id, option) => {
        Swal.fire({
            icon: 'warning',
            text: `Are you sure you want to ${option} this admin?`,
            showCancelButton: true,
            confirmButtonText: 'Yes, Proceed',
            cancelButtonText: 'No, Cancel'
        })
        .then((result) => {
            if (!result.isConfirmed) return;
            manageAdmin(id, option)
        })
    }

    const manageAdmin = (id, option) => {
        const action = option === 'deactivate' || option === 'activate' ? suspendAdmin({email: id, action: option}) : (option === 'remove' ? removeAdmin(id) : null)
        
        dispatch(action)
        .then(res => {
            if(res.payload?.success == true){
                getAdminListing()
                Swal.fire({
                    icon: "success",
                    text: res.payload?.message
                })
            }
            else {
                Swal.fire({
                    icon: "error",
                    text: res.payload.message ??  "Something went wrong!"
                })
            }
        })
    }

    const handleInput = (event) => {
        const { name, value } = event.target
        if(name === 'amount' || name === 'fund_amount') {
            const newValue = value.replace(/[^\d.]/g, '')
            setPayload((prevState)=> ({...prevState,[name]:newValue}))
        }
        else {
            setPayload((prevState)=> ({...prevState,[name]:value}))
        }
    }

    const handleValidation = (event) => {

        const regex = /^\d*\.?\d*$/;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    
        const {name, value} = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if(name === 'email' && !pattern.test(value)) {
            setErrors((prevState) => ({ ...prevState, [name]: 'Please enter a valid email address'}))
        }
        else if ((name ==='amount' || name === 'fund_amount') && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if (name ==='amount' && parseFloat(value.replace(/[^\d.]/g, '')) > 200000) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: 'You can not withdraw above maximum limit' }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }

    }

    const sendInvite = () => {
        setLoading(true)
        dispatch(createAdmin(payload))
        .then(res => {
            if(res.payload?.success == true){
                getAdminListing()
                Swal.fire({
                    icon: "success",
                    text: res.payload?.message
                })
            }
            else {
                Swal.fire({
                    icon: "error",
                    text: res.payload.message ??  "Something went wrong!"
                })
            }
        })
        .finally(() => setLoading(false))
    }
 
    return (
        <Layout title="Admin Management">
            <Container className="mt-4 px-4 px-lg-4 px-sm-2 px-md-2 mb-4">
            <Col className="d-flex justify-content-between">
                    <p className="text-primary"></p>
                    <p>
                        <Button 
                            type="button" 
                            variant="primary"
                            onClick={ () => setInvite(true) }
                        >
                            <span> <i className="lni lni-plus"></i> </span>
                            Invite Admin
                        </Button>                  
                    </p>
                </Col>
                <div className="mt-4">
                    <Card className="border-0 shadow-sm mt-5">
                        <Card.Body className="table-responsive-lg">
                            <Table size="sm" className="table-color table-stripedd">
                                <thead>
                                    <tr>
                                        <th>Admin ID</th>
                                        <th>Name</th>
                                        <th>Role</th>
                                        <th>Last Seen</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        results?.length ? (
                                            results?.map((item, index) => (
                                                <tr key={index+tab}>
                                                    <td>#443792</td>
                                                    <td>
                                                        <div className="m-0 d-flex gap-2 align-items-center">
                                                            <small className={item.is_verified === 'True' ? 'drews-badge drews-badge-success-circle' : 'drews-badge drews-badge-error-circle'}>
                                                                <img src={`https://via.placeholder.com/40`} className="rounded-circle" alt="profile photo" />
                                                            </small>
                                                            <strong>
                                                                <p className="m-0">{`${item.first_name+' '+item.last_name}`} </p>  
                                                            </strong>
                                                        </div>
                                                    </td>
                                                    <td> {item.email}  </td>
                                                    <td>{dateFormatter(item.date_joined)}</td>
                                                    <td className="d-flex">
                                                        {
                                                            item.is_active ? (
                                                                <button className="btn-plain px-3 bg-success rounded text-white">Active</button>
                                                            )
                                                            :
                                                            <button className="btn-plain px-3 bg-danger rounded text-white">Inactive</button>
                                                        }
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="light" className="border-0 bg-transparent" size="sm" id="dropdown-basic">
                                                                <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                {
                                                                    item.is_active ? (
                                                                        <Dropdown.Item 
                                                                            eventKey={1} 
                                                                            className="text-danger d-flex align-items-center gap-1" 
                                                                            onClick={() => handleManagement(item.email, 'deactivate')}
                                                                        >
                                                                            <FontAwesomeIcon icon={'cancel'} />  Deactivate
                                                                        </Dropdown.Item>
                                                                    )
                                                                    : 
                                                                        <Dropdown.Item 
                                                                            eventKey={1} 
                                                                            className="text-success d-flex align-items-center gap-1" 
                                                                            onClick={() => handleManagement(item.email, 'activate')}
                                                                        >
                                                                         <FontAwesomeIcon icon={'check-circle'} /> Activate
                                                                        </Dropdown.Item>
                                                                }
                                                                <Dropdown.Divider />
                                                                <Dropdown.Item 
                                                                    eventKey={1} 
                                                                    className="text-danger d-flex align-items-center gap-1" 
                                                                    onClick={() => handleManagement(item.id, 'remove')}
                                                                >
                                                                <FontAwesomeIcon icon={"trash"} />  Remove Admin
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                        : 
                                        (
                                            <tr>
                                                <td colSpan={5} className="text-center text-muted">No data found</td>
                                            </tr>
                                        )
                                    }
                                </tbody>                                           
                            </Table>
                        </Card.Body>
                    </Card>
                    {
                        results?.length ? (
                            <div className="table-responsive mt-2">
                                <Paginator
                                    next={paginationData.next} 
                                    prev={paginationData.prev}
                                    setCurrentPage={ handlePagination }
                                />
                            </div>
                        ) : null
                    }
                </div>
                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={invite}
                    onHide={() => setInvite(false)}
                    backdrop="static"
                >
                    {/* <Modal.Header className="text-center">Invite Admin</Modal.Header> */}
                    <Modal.Body className="py-4 px-5">
                        <h5 className="text-center">Invite Admin</h5>
                        <Row>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0 text-sm">First Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="First Name"
                                    name="first_name"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['first_name'] && 
                                    <Form.Text className="text-danger">{errors['first_name']}</Form.Text>
                                }
                            </Form.Group>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0 text-sm">Last Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Last Name"
                                    name="last_name"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['last_name'] && 
                                    <Form.Text className="text-danger">{errors['last_name']}</Form.Text>
                                }
                            </Form.Group>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0 text-sm">Email</Form.Label>
                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="Email"
                                    name="email"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['email'] && 
                                    <Form.Text className="text-danger">{errors['email']}</Form.Text>
                                }
                            </Form.Group>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0 text-sm">Role</Form.Label>
                                <Form.Select
                                    required
                                    name="role"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                >
                                    <option value="">Please select a role...</option>
                                    <option value="support">Support</option>
                                    <option value="sales">Sales</option>
                                    <option value="admin">Admin</option>
                                </Form.Select>
                                {
                                    errors['role'] && 
                                    <Form.Text className="text-danger">{errors['role']}</Form.Text>
                                }
                            </Form.Group>
                            <Form.Group as={Col} md={12} className="text-center mb-3 mt-3">
                                <Button 
                                    variant="outline-primary" 
                                    type="button" 
                                    onClick={() => setInvite(false)}
                                    className="px-4 me-2"
                                > 
                                    Cancel
                                </Button>
                                <Button 
                                    variant="primary" 
                                    className="px-4 ms-2" 
                                    type="button"
                                    onClick={sendInvite}
                                > 
                                    Submit <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </Form.Group>
                        </Row>
                    </Modal.Body>
                </Modal>
            </Container>
        </Layout>
    );
}

export default AdminManagement;